
/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
    return true;
}

/**
 * 6~16位，不包含空格
 * @returns 
 */
export function loginPassworValidate(str) {
    return /^[^\s]{6,16}$/.test(str);
}


export function _isEmpty(input) {
    return String(input) === 'null' || String(input) === 'undefined' || input === '';
}
