import { URL_PREFIX } from '@/const/const.js';

export default class ViewDataServer {

    // 树形结构
    static API_GET_DATASET_DETAIL_CELLTYPE_HIERARCHY_BY_ID = URL_PREFIX + "/user/user_datasets/{stringId}/celltype_hierarchy2";

    // 获取数据集umap图分页数据
    static DATABASE_API_USER_POST_EMBEDDING_ANNOTATION_DETAIL = URL_PREFIX + '/user/user_datasets/{id}/embedding_annotation';

    // 获取数据改变数据集相关参数配置，获取分页total
    static DATABASE_API_USER_GET_EMBEDDING_ANNOTATION_LIST = URL_PREFIX + '/user/user_datasets/{id}/embedding_annotation';

    // get方式 获取用户umap 不降点坐标数据类型，占比，数量
    static DATABASE_API_USER_GET_OBSERVATION_ANNOTATION_VIEW2_DETAIL = URL_PREFIX + '/remote/user/user_datasets/{id}/observation_annotation_view2';

    // 过滤group分组接口
    static USER_DATABASE_API_GET_DIFFERENT_EXPRESSION = URL_PREFIX + '/user/user_datasets/{stringId}/differential_expression';

    // 查询用户数据集关系
    static USER_DATABASE_API_GET_RELATION = URL_PREFIX + '/user/user_datasets/{id}/dataset_relation';

    // 用户数据集umap -- 坐标
    static USER_DATABASE_API_DATESET_GET_EMBEDDING = URL_PREFIX + "/user/user_datasets/{id}/embedding";

    // 用户数据集umap --- 着色
    static USER_DATABASE_API_DATESET_GET_OR_POST_OBSERVATION_ANNOTATIION = URL_PREFIX + "/user/user_datasets/{stringId}/observation_annotation";

    // get方式 获取用户umap 不降低点坐标数据, 坐标范围
    static DATABASE_API_USER_GET_EMBEDDING_VIEW2_DETAIL = URL_PREFIX + '/remote/user/user_datasets/{id}/embedding_view2';

    // 获取数据集详情
    static USER_DATABASE_API_DATESET_GET_DATASETS_DETAIL = URL_PREFIX + "/remote/user/user_datasets/{stringId}";

    // user observation_gene
    static USER_DATABASE_API_DATESET_GET_OBSERVATION_GENE = URL_PREFIX + "/user/user_datasets/{stringId}/observation_gene";

    // 获取subset列表
    static API_GET_OBSERVATION_SUBSET_BY_ID = URL_PREFIX + '/user/user_datasets/{id}/observation_subset';

    // 复制subset列类型
    static API_POST_COPY_OBSERVATION_SUBSET_BY_ID = URL_PREFIX + '/user/user_datasets/{id}/observation_subset';

    // 删除subset列类型
    static API_DELETE_OBSERVATION_SUBSET_BY_ID = URL_PREFIX + '/user/user_datasets/{id}/observation_subset';

    // 编辑subset列类型
    static API_POST_EDIT_OBSERVATION_SUBSET_BY_ID = URL_PREFIX + '/remote/user/user_datasets/{id}/observation_edit_subset';

    //合并subset列类型
    static API_POST_MERGE_OBSERVATION_SUBSET_BY_ID = URL_PREFIX + '/remote/user/user_datasets/{id}/observation_merge_subset';

    // 检查子集是否有相关子集
    static API_GET_CHECKOUT_RELEVANT_SUBSET = URL_PREFIX + '/remote/user/user_datasets/{id}/observation_edit_subset';

    static API_POST_CALCULATE_DEGS_TASK_BY_ID = URL_PREFIX + '/remote/user/user_datasets/{id}/calculate_deg_task';

    // map cell types
    static API_POST_MAP_CELL_TYPE = URL_PREFIX + '/remote/user/user_datasets/{id}/map_cell_type';
    // 查询用户数据集可映射关系 dataset_mapping_relation
    static API_GET_DATASET_MAPPING_RELATION = URL_PREFIX + '/remote/user/user_datasets/{id}/dataset_mapping_relation';


    // observations颜色相关
    static API_GET_POST_OBSERVATION_COLORS = URL_PREFIX + '/remote/user/user_datasets/{id}/observation_color_manager';
}