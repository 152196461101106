export const projectSite = {
    cn: {
        bi: 'https://www.synecosys.cn/',
        celeviz: 'https://www.synecosys.cn/#/celevizbrowser/',
        celelens: 'https://www.celelenscloud.cn/',
    },
    com: {
        bi: 'https://www.synecosys.com/',
        celeviz: 'https://www.synecosys.com/#/celevizbrowser/',
        celelens: 'https://celelenscloud.singleron.bio/#/',
    },
};

export const termSite = {
    term: {
        CN: 'https://download.synecosys.cn/privacyfiles/ce-termsOfUseCN.html',
        other: 'https://download.synecosys.cn/privacyfiles/ce-termsofuse.html',
    },
    privacy: {
        CN: 'https://download.synecosys.cn/privacyfiles/ce-privacyPolicyCN.html',
        other: 'https://download.synecosys.cn/privacyfiles/ce-privacyPolicy.html',
    },
};