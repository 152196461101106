export const intergrateDatasetsCN = {
    'Integrated.Input.Placeholder': '搜索分析ID/任务名称',
    'Integrated.Input.File.name': '搜索文件名',
    'Integrated.Input.File.Job.name': '搜索任务名称/文件名',
    'Integrated.Filter.By.Status': '任务状态过滤',
    'Integrated.Select.Options': '选项',
    'Integrated.Analysis.ID': '分析ID',
    'Integrated.Submitted': '提交时间',
    // 'Integrated.Running.Time': 'Running time',
    'Integrated.Error': '错误',
    'Integrated.Completed': '完成',
    'Integrated.Sample.Info': '样本信息',

    'Integrated.Original.Analysis.ID': '主任务分析ID',
    'Integrated.Original.Job.Name': '主任务任务名称',

    // 不同任务展示信息
    'Integrated.Task.Type.Gene.Set.Enrichment': '基因集富集分析',
    'Integrated.Gene.set.Num': 'Gene set',
    'Integrated.Gene.Set.Names': 'Genes set names',
    'Integrated.Gene.String': 'Genes',

    'Integrated.Task.Type.Deg.Enrichment': '差异基因富集分析',
    'Integrated.Deg.Group.Num': 'Group',

    // monocle2
    'Integrated.MonoCle.Major.Cell.Types': 'Major cell types',
    'Integrated.Monocle.Sample.ID': 'Sample ID',
    'Integrated.Monocle.Reverse.Pseudotime': 'Reverse pseudotime',
    'Integrated.Monocle.Show.Branch.Point': 'Show branch point',
    'Integrated.Monocle.Show.Tree': 'Show tree',
    "Integrated.Monocle.Specify.Root.Cluster": 'Specify root cluster',

    // trajectory
    'Integrated.Monocle.Cell.Types': 'Cell types',

    // cellphonedb
    'Integrated.CellphoneDB.Method': 'Method',
    'Integrated.CellphoneDB.Maximum.Cells.Per.Cell.Type': 'Maximum cells per cell type',
    'Integrated.CellphoneDB.Iterations': 'Iterations',
    'Integrated.CellphoneDB.P.Value': 'P value',

    //cnv
    'Integrated.CNV.Cells.To.Test.CNV': 'Cells to test CNV',
    'Integrated.CNV.Select.Normal.Cells.As.Reference': 'Select normal cells as reference',

    // ith


};
export const intergrateDatasetsUS = {
    'Integrated.Input.File.name': 'Search file name',
    'Integrated.Input.File.Job.name': 'Search job name/file name',
    'Integrated.Input.Placeholder': 'Search analysis ID/job name',
    'Integrated.Filter.By.Status': 'Filter by status',
    'Integrated.Select.Options': 'select options',
    'Integrated.Analysis.ID': 'Analysis ID',
    'Integrated.Submitted': 'Submitted',
    // 'Integrated.Running.Time': 'Running time',
    'Integrated.Error': 'Error',
    'Integrated.Completed': 'Completed',
    'Integrated.Sample.Info': 'Sample info',

    'Integrated.Original.Analysis.ID': 'Original Analysis ID',
    'Integrated.Original.Job.Name': 'Original job name',

    // 不同任务展示信息
    'Integrated.Task.Type.Gene.Set.Enrichment': 'Gene set enrichment',
    'Integrated.Gene.set.Num': 'Gene set',
    'Integrated.Gene.Set.Names': 'Genes set names',
    'Integrated.Gene.String': 'Genes',

    'Integrated.Task.Type.Deg.Enrichment': 'DEG enrichment',
    'Integrated.Deg.Group.Num': 'Group',

    // monocle2
    'Integrated.MonoCle.Major.Cell.Types': 'Major cell types',
    'Integrated.Monocle.Sample.ID': 'Sample ID',
    'Integrated.Monocle.Reverse.Pseudotime': 'Reverse pseudotime11',
    'Integrated.Monocle.Show.Branch.Point': 'Show branch point',
    'Integrated.Monocle.Show.Tree': 'Show tree',
    "Integrated.Monocle.Specify.Root.Cluster": 'Specify root cluster',

    // trajectory
    'Integrated.Monocle.Cell.Types': 'Cell types',

    // cellphonedb
    'Integrated.CellphoneDB.Method': 'Method',
    'Integrated.CellphoneDB.Maximum.Cells.Per.Cell.Type': 'Maximum cells per cell type',
    'Integrated.CellphoneDB.Iterations': 'Iterations',
    'Integrated.CellphoneDB.P.Value': 'P value',

    //cnv
    'Integrated.CNV.Cells.To.Test.CNV': 'Cells to test CNV',
    'Integrated.CNV.Select.Normal.Cells.As.Reference': 'Select normal cells as reference',

    // ith

};