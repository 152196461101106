<template>
  <div id="app" class="main">
    <div class="content">
      <router-view v-if="!$route.path.startsWith('/app1')" />
      <div class="plugin-tools" v-else>
        <nav-menu-comp></nav-menu-comp>
        <div id="DrawingTools"></div>
      </div>
    </div>
    <div class="weChat-wrapper">
      <we-chat />
    </div>
    <div class="bottom-footer">
      <singleron-footer-comp v-if="!$route.meta.hideFooter" />
    </div>
    
  </div>
</template>
<script>
import SingleronFooterComp from "@/views/production/footer";
import navMenuComp from '@/components/nav/index.vue';
import { isCNSite } from "@/utils";
import weChat from "@/components/weChat";
export default {
  name: 'App',
  components: {
    SingleronFooterComp,
		'nav-menu-comp': navMenuComp,
    weChat,
  },
  created(){
    this.setI18nLanguage();
  },
  methods:{
    setI18nLanguage() {
      const defaultLang = isCNSite() ?  'zh-CN' : 'en-US';
      document.querySelector('html').setAttribute('lang', defaultLang);
    },
  },
};
</script>
<style lang="scss" scoped>


.main {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 0 100vh; // 940
}
.bottom-footer {
  flex: 0;
}
.plugin-tools {
  padding-top: 100px;
  margin: auto;
  width: 85%;
}
</style>
