export default [

    {
        path: '/',
        name: 'production',
        disable: false,
        isShow: true,
        // component: () => import(/* webpackChunkName: "login" */'@/views/login/SingleronMainNavEntryComp.vue'),
        component: () => import(/* webpackChunkName: "login" */'@/views/production/SingleronMainEntryComp.vue'),
        children: [],
        meta: {
            hideFooter: true,
        },
    },
    {
        path: '/migration',
        name: 'migration',
        disable: false,
        isShow: true,
        // component: () => import(/* webpackChunkName: "login" */'@/views/login/SingleronMainNavEntryComp.vue'),
        component: () => import(/* webpackChunkName: "login" */'@/views/tipsPage/migrate/index.vue'),
        children: [],
        meta: {
            hideFooter: true,
        },
    },
    {
        path: '/domain',
        name: 'domain',
        disable: false,
        isShow: true,
        // component: () => import(/* webpackChunkName: "login" */'@/views/login/SingleronMainNavEntryComp.vue'),
        component: () => import(/* webpackChunkName: "login" */'@/views/tipsPage/stopUpdating/index.vue'),
        children: [],
        meta: {
            hideFooter: true,
        },
    },
    {
        path: '/login',
        name: 'login',
        disable: false,
        // isShow: true,
        component: () => import(/* webpackChunkName: "login" */'@/views/production/login/index.vue'),
        meta: {
            hideFooter: true,
        },
    },
    {
        path: '*',
        component: () => import(/* webpackChunkName: "NotFoundPage" */'@/views/NotFoundPage/index.vue'),
    },
    {
        path: '/celelens',
        component: () => import(/* webpackChunkName: "home" */'@/components/pageLayout/index.vue'),
        children: [{
            path: '/my_analyzes',
            name: 'MyAnalyzes',
            alias: '/my_analyzes',
            component: resolve => require(['@/views/myAnalyses/SingleronMyAnalysesEntryComp.vue'], resolve),
            meta: {
                title: 'MyAnalyzes',
            },
        },{
            path: '/requestedDownloads',
            name: 'RequestedDownloads',
            alias: '/requestedDownloads',
            component: resolve => require(['@/views/myAccount/requestDownload/SingleronRequestDownloadEntryComp.vue'], resolve),
            meta: {
                title: 'RequestedDownloads',
            },
        },{
            path: '/notifications',
            name: 'Notifications',
            alias: '/notifications',
            component: resolve => require(['@/views/myAccount/notifications/SingleronNotificationsComp.vue'], resolve),
            meta: {
                title: 'notifications',
            },
        },{
            path: '/accountsettings',
            name: 'AccountSettings',
            alias: '/accountsettings',
            component: resolve => require(['@/views/myAccount/accountInfo/AccountSettings/index.vue'], resolve),
            meta: {
                title: 'AccountSettings',
            },
        },{
            path: '/importdata',
            name: 'ImportData',
            component: resolve => require(['@/views/importData/SingleronImportDataEntryComp'], resolve),
            meta: {
                title: 'ImportData',
            },
        },
        {
            path: '/integrateLocalData',
            name: 'IntegrateLocalData',
            component: resolve => require(['@/views/integrateLocalSamples/index.vue'], resolve),
            meta: {
                title: 'IntegrateLocalData',
            },
        },
        {
            path: '/integrateLocalAndSynEcoSys',
            name: 'integrateLocalAndSynEcoSys',
            component: resolve => require(['@/views/integrateSesAndLocal/index.vue'], resolve),
            meta: {
                title: 'integrateLocalAndSynEcoSys',
            },
        },
        {
            path: '/integrateSynEcoSysData',
            name: 'IntegrateSynEcoSysData',
            component: resolve => require(['@/views/integrateSesDatasets/index.vue'], resolve),
            meta: {
                title: 'IntegrateSesDatasets',
            },
        }, { // 用户数据集
            path: '/userDatasetAnalysis',
            name: 'UserDatasetAnalysis',
            component: resolve => require(['@/views/userDatasetAnalysis/index.vue'], resolve),
            meta: {
                title: 'UserDatasetAnalysis',
            },
            // beforeEnter: checkServerUpdatePlan,
        }, { // 子数据集
            path: '/userSubCelltypeDatasetAnalysis',
            name: 'UserSubCelltypeDatasetAnalysis',
            component: resolve => require(['@/views/userDatasetAnalysis/index.vue'], resolve),
            meta: {
                title: 'UserSubCelltypeDatasetAnalysis',
            },
        },{
                path: '/guidebook',
                name: 'GuideBook',
                component: resolve => require(['@/views/Resources/guideBook.vue'], resolve),
                meta: {
                    title: 'GuideBook',
                },
            },{
                path: '/documentation',
                name: 'Documentation',
                component: resolve => require(['@/views/Resources/Documentation.vue'], resolve),
                meta: {
                    title: 'Documentation',
                },
            },{
                path: '/contact',
                name: 'Contact',
                component: resolve => require(['@/views/Resources/Contact.vue'], resolve),
                meta: {
                    title: 'Contact',
                },
            },{
                path: '/faq',
                name: 'FAQ',
                component: resolve => require(['@/views/Resources/SingleronFaqComp.vue'], resolve),
                meta: {
                    title: 'FAQ',
                },
            },
            {
                path: '/tutorialVideos',
                name: 'TutorialVideos',
                component: resolve => require(['@/views/Resources/tutorialVideos/index.vue'], resolve),
                meta: {
                    title: 'TutorialVideos',
                },
            },
        ],
    },
];