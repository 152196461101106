/*
 * @Author: your name
 * @Date: 2021-10-25 14:37:06
 * @LastEditTime: 2021-12-29 18:57:06
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \database_app\src\utils\auth.js
 */
// import { _isEmpty } from '@/utils/singleronbio';
// import server from '@/utils/server';
import store from '@/store';
import { MessageBox } from 'element-ui';
import { mainMenuListCNUS } from "@/const/menuList";
// export function getToken() {
//   return _isEmpty(server.getUserInfo()) ? '' : server.getUserInfo().token;
// }
//
// export function setToken(token) {
//   if (_isEmpty(server.getUserInfo())) {
//     server.saveUserInfo({ token: token });
//   } else {
//     const user = server.getUserInfo();
//     user.token = token;
//     server.saveUserInfo(user);
//   }
// }
//
// export function removeToken() {
//   if (_isEmpty(server.getUserInfo())) {
//     return;
//   }
//   const user = server.getUserInfo();
//   user.token = null;
//   server.saveUserInfo(user);
// }
//
// export function getSid() {
//   return _isEmpty(server.getUserInfo()) ? '' : server.getUserInfo().sid;
// }
//
// export function setSid(sid) {
//   if (_isEmpty(server.getUserInfo())) {
//     server.saveUserInfo({ sid: sid });
//   } else {
//     const user = server.getUserInfo();
//     user.sid = sid;
//     server.saveUserInfo(user);
//   }
// }
//
// export function removeSid() {
//   if (_isEmpty(server.getUserInfo())) {
//       return;
//   }
//   const user = server.getUserInfo();
//   user.sid = null;
//   server.saveUserInfo(user);
// }

// 处理按钮权限
function handleOperAuth(Arr) {
  const formatData = Arr.map(item => {
    return {
      name: item.name,
      id: item.permission,
      disabled: item.disabled,
      hidden: item.hidden,
    };
  });

  const disabledOperOfTrue = formatData.filter(item => item.disabled);
  const hiddenOperOfTrue = formatData.filter(item => item.hidden);
  store.dispatch("routesList/setDisabledOperListA", disabledOperOfTrue);
  store.dispatch("routesList/setHiddenOperListA", hiddenOperOfTrue);
}
// 处理菜单权限
// 处理菜单权限
// my account 特殊处理
const username = 'Celelens:UserName';
export function handleUserMenuAuth(data) {
  if(!data.length) {
    return;
  }
  // type等于2是按钮
  const openAuth = data.filter(item => item.type === 2);
  handleOperAuth(openAuth);
  // type不等于2为菜单
  const menuAuth = data.filter(item => item.type !== 2);
  const formatData = menuAuth.map(item => {
    return {
      name: item.name,
      id: item.permission,
      children: [],
      routePath: item.url,
      active: false,
      disabled: item.disabled,
      hidden: item.hidden,
      parentId: item.parentId,
      selfId: item.id,
      label: mainMenuListCNUS[item.name],
    };
  });
  store.dispatch('routesList/setFlatRouterListByUser', JSON.parse(JSON.stringify(formatData)));
  formatData.forEach(ele => {
    if(ele.parentId) {
      const filterData = formatData.find(item => item.selfId === ele.parentId);
      filterData.children.push(ele);
    }
  });

  const filterData = formatData.filter(item => !item.parentId);

  // 添加登出操作
  const userItem = filterData.find(item => item.id === username);
  if (userItem) {
    userItem.children.push({
      name: 'Logout',
      id: 'Logout',
      children: [],
      active: false,
      disabled: false,
      label: 'Menu.List.Logout',
    });
  }
  store.dispatch("routesList/setRoutesList", filterData);
}

// 权限提示
export function messageBox() {
  MessageBox.confirm('Please request a subscription, and get more access to explore SynEcoSys !', 'Tip', {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      type: 'warning',
    }).then(() => {
      window.location.href="#/accountsettings";
    }).catch(() => {
  });
}

