import Vue from 'vue';
import VueRouter from 'vue-router';
import common from './common';
import store from "@/store";
import server from "@/server/request";
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

Vue.use(VueRouter);

const routes = [
  ...common,
];


// 加载静态路由
const createRouter = () =>
    new VueRouter({
      routes: routes,
    });

// 创建路由
const router = createRouter();

// 重置路由
export function resetRouter() {
  router.matcher = createRouter().matcher;
}
// const router = new VueRouter({
//   base: process.env.BASE_URL,
//   routes,
// });



// 捕获push replace中的错误
// 当然在replace中的错误也是可以相同的进行捕获
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {return originalPush.call(this, location, onResolve, onReject);}
  return originalPush.call(this, location).catch(err => err);
};
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {return originalReplace.call(this, location, onResolve, onReject);}
  return originalReplace.call(this, location).catch(err => err);
};

const whiteList = ['/login', '/404', '/', '/migration', '/domain']; // 定义白名单，所有不受权限控制的页面
router.beforeEach((to, from, next) => {
    // console.log('1111111', to.path);
    NProgress.configure({ showSpinner: false });
    NProgress.start();
    store.dispatch('routesList/changeRoutePathA', to.path);
    // next();
    if(server.getToken()) {
        const flatRouterList = store.state.routesList.flatRouterList;
        const filterArray = flatRouterList.filter(item => item.routePath === to.path);
        if(filterArray.length) {
            const obj = filterArray[0];
            if(obj.disabled || obj.hidden) {
                next('/404');
            }
        }
        // 有token
        next();
    } else {
        // 没有token
        if (whiteList.includes(to.path)) {
            // 白名单
            next();
        } else if(to.path) {
            next('/login?redirect=' + to.fullPath);
            NProgress.done();
        } else {
            next();
        }
    }
});
router.afterEach(() => {
    NProgress.done();
});

export default router;
