export const faqCN = {
    'Faq.Title.FAQ': 'FAQ',
    'Faq.Tabs.About.Cell.Annotation': 'About cell annotation',
    'Faq.Tabs.About.Uploaded.With.CeleLens': 'About uploaded data analysis with CeleLens',
    'Faq.Tabs.About.Public.Data.curation': 'About public data curation',
    'Faq.Tabs.About.Usage.Instructions': 'About usage instructions',
    'Faq.Message.No.Result.For': 'No result for',
};
export const faqUS = {
    'Faq.Title.FAQ': 'FAQ',
    'Faq.Tabs.About.Cell.Annotation': 'About cell annotation',
    'Faq.Tabs.About.Uploaded.With.CeleLens': 'About uploaded data analysis with CeleLens',
    'Faq.Tabs.About.Public.Data.curation': 'About public data curation',
    'Faq.Tabs.About.Usage.Instructions': 'About usage instructions',
    'Faq.Message.No.Result.For': 'No result for',
};