import Vue from 'vue';
import VueI18n from 'vue-i18n';
import locale from 'element-ui/lib/locale';
import moment from 'moment';
import enLocale from "element-ui/lib/locale/lang/en";
import zhLocale from "element-ui/lib/locale/lang/zh-CN";
import zhCN from './lang/zh-CN';
import enUS from './lang/en-US';
import { isCNSite } from "@/utils";
import { actions } from '@/qiankun/globalState';
Vue.use(VueI18n);

export const defaultLang = isCNSite() ?  'zh-CN' : 'en-US';

const messages = {
  'zh-CN': {
    ...zhCN,
    ...zhLocale,
  },
  'en-US': {
    ...enUS,
    ...enLocale,
  },
};

const i18n = new VueI18n({
  silentTranslationWarn: true,
  locale: defaultLang,
  fallbackLocale: defaultLang,
  messages,
});

const loadedLanguages = [defaultLang];

function setI18nLanguage(lang) {
  i18n.locale = lang;
  // request.headers['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang);
  return lang;
}

export function loadLanguageAsync(lang = defaultLang) {
  return new Promise(resolve => {
    // 缓存语言设置
    window.localStorage.setItem('lang', lang);
    actions.setGlobalState({
      lang,
    });
    if (i18n.locale !== lang) {
      if (!loadedLanguages.includes(lang)) {
        return require([`@/locales/lang/${lang}.js`], (msg) => {
          const locale = msg.default;
          i18n.mergeLocaleMessage(lang, locale);//既要做网站的多语言，又需要element框架的国际化时，就需要用mergeLocaleMessage
          loadedLanguages.push(lang);
          moment.updateLocale(locale.momentName, locale.momentLocale);
          return setI18nLanguage(lang);
        });
      }
      return resolve(setI18nLanguage(lang));
    }
    return resolve(lang);
  });
}

export function i18nRender(key) {
  return i18n.t(`${key}`);
}

locale.i18n((key, value) => i18n.t(key, value)); //为了实现element插件的多语言切换

export default i18n;
