export const tfRegulationCN = {
    'tf.Explore.title': '转录调控网络分析',
    'tf.Tab.title': '转录调控网络分析',
    'tf.Step.One.regulation.Title': 'Regulon',
    'tf.regulation.result1': 'Regulon 特异性散点图 - top 5 regulons',
    'tf.regulation.result2': '所选细胞中的Regulon AUC 矩阵聚类热图',
    'tf.regulation.result3': ' 所选分组中的Regulon AUC矩阵聚类热图',
    'tf.regulation.result4': '所选分组中的Regulon平均表达热图',

    'tf.Step.Two.regulation.Title': '基于regulon连接特异性指数（CSI）矩阵识别regulon模块',
    'tf.Step.Two.Number.Of.Modules': '模块数量: ',
    'tf.Step.Two.Number.Of.Modules.Tips':'根据转录因子CSI(连接特异性指数)分数，将regulon聚类成指定数量的功能模块，进而反映regulon在转录调控网络中的相似性和差异性。',
    'tf.Export.Data': '导出数据',
    'tf.Export.Data.Tips': '导出当前分析板块的结果数据。部分文件可直接导入工具板块中作为作图数据。',
};

export const tfRegulationUS = {
    'tf.Explore.title': 'Explore Transcription factor regulation',
    'tf.Tab.title': 'TF regulation',
    'tf.Step.One.regulation.Title': 'Regulon',
    'tf.regulation.result1': 'Regulon specificity score – top 5 regulons',
    'tf.regulation.result2': 'Regulon AUC heatmap in selected cells',
    'tf.regulation.result3': 'Regulon AUC heatmap in selected groups',
    'tf.regulation.result4': 'Regulon expression heatmap in selected groups',

    'tf.Step.Two.regulation.Title': 'Identified regulon modules based on regulon connection specificity index (CSI) matrix',

    'tf.Step.Two.Number.Of.Modules': 'Number of modules: ',

    'tf.Step.Two.Number.Of.Modules.Tips': 'Based on transcription factor CSI scores, regulons are clustered into a specified number of functional modules, reflecting the similarity of regulons in the transcriptional regulatory network.',

    'tf.Export.Data': 'Export data',
    'tf.Export.Data.Tips': 'Export the result data from the current analysis section. Some files can be directly imported into the Tool section as graphical data.',
};