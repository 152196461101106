import ServerConstants from './server.js';
import server from '@/server/request.js';
/**
 * 登录
 * **/
export function userLogin(params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.postForm(ServerConstants.API_POST_USER_LOGIN, params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}


/**
 * 检查用户名是否已经存在
 * **/
export function check_username(params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.getJSON(ServerConstants.DATABASE_API_USER_CHECK_USERNAME, params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}

/**
 * 检查邮箱是否已经存在
 * **/
export function check_email(params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.getJSON(ServerConstants.DATABASE_API_USER_CHECK_EMAIL, params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}

/**
 * 检查手机号是否已经存在
 * **/
export function check_phone(params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.getJSON(ServerConstants.DATABASE_API_USER_CHECK_PHONE, params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}

/**
 * 注册页-获取验证码
 * **/
export function get_email_code(params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.postForm(ServerConstants.DATABASE_API_USER_SEND_MESSAGE, params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}

/**
 * 注册页--- 点击注册按钮
 * **/
export function reg(params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.postForm(ServerConstants.DATABASE_API_USER_REG_URL, params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}


// 用户退出登录
export function userLogout(params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.getJSON(ServerConstants.API_GET_USER_LOGOUT, params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}

// 用户基本信息
export function getUserInfo(params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.getJSON(ServerConstants.API_GET_USER_INFO, params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}


// 忘记密码
export function forgot_password(params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.postJSON(ServerConstants.USER_DATABASE_API_GET_FORGOT_PASSWORD, params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}

/**
 * 判断ip所属国家
 * @param {*} success
 * @param {*} errorfun
 * @returns
 */
export function checkIpAddress(success, errorfun) {
    return new Promise((resolve, reject) => {
        server.getJSON(ServerConstants.API_GET_IP_ADDRESS, {}, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}