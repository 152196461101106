import { degsTableCN, degsTableUS } from "@/locales/lang_modules/dataAnalysisPage/degs";
import { exploreTabsNameCN, exploreTabsNameUS } from "@/locales/lang_modules/dataAnalysisPage/exploreTabsName";
import { umapCN, umapUS } from "@/locales/lang_modules/dataAnalysisPage/umap";
import { geneExpressionCN, geneExpressionUS } from "@/locales/lang_modules/dataAnalysisPage/geneExpression";
import { cellularCompositionCN, cellularCompositionUS} from "@/locales/lang_modules/dataAnalysisPage/cellularComposition";
import { DifferentialExpressionCN, DifferentialExpressionUS} from "@/locales/lang_modules/dataAnalysisPage/DifferentialExpression";
import { cellInteractionCN, cellInteractionUS} from "@/locales/lang_modules/dataAnalysisPage/cellInteraction";
import { monocleCN, monocleUS } from "@/locales/lang_modules/dataAnalysisPage/monocle";
import { cnvCN, cnvUS } from "@/locales/lang_modules/dataAnalysisPage/cnv";
import { subsetCN, subsetUS } from './subset.js';
// 公共部分
const dataAnalysisCN = {
    'Data.Analysis.Title.Select.Subset': '选择子集',
    'Data.Analysis.File.Download': '结果文件下载',
    'Data.Analysis.Select.Group': '选择分组',
    'Data.Analysis.Cell.Types': 'Cell types',
    'Data.Analysis.From.Sample.Group': 'From sample group',
    'Data.Analysis.Filter': '过滤',
    'Data.Analysis.Groups': '分组',
    'Data.Analysis.VS': 'vs',
    'Data.Analysis.Type.In.Job.Name': '任务名称*',
    'Data.Analysis.Select.Job': '选择任务',
    'Data.Analysis.No.Data': 'No data',
    'data.Analysis.Name': '名称',
    'Data.Analysis.Custom.Gene.List': '我的基因列表',
    'Data.Analysis.Type.Genes.Placeholder': '请输入基因列表名称',
    'Data.Analysis.Type.Genes': '请输入基因',
    'Data.Analysis.Create.New.Gene.List': '创建新的列表: ',
    'Data.Analysis.Add.Existing.List': '添加到已有列表',
    'Data.Analysis.Please.Type.Genes': '请输入基因',
    'Data.Analysis.Type.Gene.Symbol': '请输入基因',
    'Data.Analysis.Custom.Gene.Download': '下载我的基因列表',

    'Data.Analysis.Method': '方法*:', // Method*:
    'Data.Analysis.SampleID': '样本ID', // Sample ID*:
    'Data.Analysis.Cell.types': '细胞类型*: ', //Cell types*:
    'Data.Analysis.Cell.types1': '细胞类型', //Cell types*:
    'Data.Analysis.Samples': '样本: ',
    'Data.Analysis.Task.Tips': '已提交到后台分析。您可在"我的分析"中查看或修改。',
    'Data.Analysis.Task.Tips1': '已提交到后台分析。',
    'Data.Analysis.Task.Fail': '失败', // fail
    'Data.Analysis.Download': '下载',
    'Data.Analysis.Loading': '加载中',

    'Data.Analysis.Download.Image.Message': '请等待下载开始',
};

const dataAnalysisUS = {
    'Data.Analysis.Title.Select.Subset': 'Select subset',
    'Data.Analysis.File.Download': 'file download',
    'Data.Analysis.Select.Group': 'Select Group',
    'Data.Analysis.Cell.Types': 'Cell types',
    'Data.Analysis.From.Sample.Group': 'From sample group',
    'Data.Analysis.Filter': 'Filter',
    'Data.Analysis.Groups': 'Groups',
    'Data.Analysis.VS': 'vs',
    'Data.Analysis.Type.In.Job.Name': 'Job name*',
    'Data.Analysis.Select.Job': 'Select Job',
    'Data.Analysis.No.Data': 'No data',
    'data.Analysis.Name': 'Name',
    'Data.Analysis.Custom.Gene.List': 'Custom Gene List',
    'Data.Analysis.Type.Genes.Placeholder': 'Type in gene list name',
    'Data.Analysis.Type.Genes': 'Type genes',
    'Data.Analysis.Create.New.Gene.List': 'Create new gene list: ',
    'Data.Analysis.Add.Existing.List': 'Or add to existing gene list',
    'Data.Analysis.Please.Type.Genes': 'Please type genes',
    'Data.Analysis.Type.Gene.Symbol': 'Type in gene symbol',
    'Data.Analysis.Custom.Gene.Download': 'Custom gene list download',

    'Data.Analysis.Method': 'Method*: ',
    'Data.Analysis.SampleID': 'Sample ID',
    'Data.Analysis.Samples': 'Samples: ',
    'Data.Analysis.Cell.types': 'Cell types*: ',
    'Data.Analysis.Cell.types1': 'Cell types',
    'Data.Analysis.Task.Tips': 'has been submitted to the server. You can modify the job in My Analyses.',
    'Data.Analysis.Task.Tips1': 'has been submitted to the server.',
    'Data.Analysis.Task.Fail': 'Fail',
    'Data.Analysis.Download': 'download',
    'Data.Analysis.Loading': 'loading',

    'Data.Analysis.Download.Image.Message': 'Please wait for download to start',

};

export const dataAnalysisPageCN = {
    ...dataAnalysisCN,
    ...degsTableCN,
    ...umapCN,
    ...exploreTabsNameCN,
    ...geneExpressionCN,
    ...cellularCompositionCN,
    ...DifferentialExpressionCN,
    ...cellInteractionCN,
    ...monocleCN,
    ...cnvCN,
    ...subsetCN,
};

export const dataAnalysisPageUS = {
    ...dataAnalysisUS,
    ...degsTableUS,
    ...umapUS,
    ...exploreTabsNameUS,
    ...geneExpressionUS,
    ...cellularCompositionUS,
    ...DifferentialExpressionUS,
    ...cellInteractionUS,
    ...monocleUS,
    ...cnvUS,
    ...subsetUS,
};


