import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import user from '@/store/modules/user';
import changeUmap from "./modules/changeUmap.js";
import dataset from "./modules/dataset";
import themeConfig from '@/store/modules/themeConfig.js';
import routesList from "@/store/modules/routesList";
import app from './modules/app';
import changeColor from "@/store/modules/changeColor";
import downloadImage from "@/store/modules/downloadImage";
import spatialTranscriptomicsInfo from "@/store/modules/spatialTranscriptomicsInfo";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  getters,
  mutations: {
  },
  actions: {
  },
  modules: {
    app,
    user,
    changeUmap,
    dataset,
    routesList,
    themeConfig,
    changeColor,
    downloadImage,
    spatialTranscriptomicsInfo,
  },
});

