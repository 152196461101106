import { findNodePath, findNode } from "@/utils/getTreeNodes.js";
import {Local, Session} from "@/utils/storage";
import { handleUserMenuAuth } from '@/utils/auth';
import { getUserUnReadNotifications } from "@/api/myAccount/notifications";

export default ({
  name: 'SingleronMobileMenus',
  components: {
	},
  data() {
    return {
      isCollapse: true,
      menusList: [],
      userName: 'My analyses',
      unReadNotifications: 0,
    };
  },
  props: {
  },
  watch: {
  },
  mounted(){
    this.initData();
  },
  methods:{
    // 处理菜单权限
    handleAuth() {
      const userInfo = Local.get('user_info');
      if(!userInfo) {return;}
      const menu =  userInfo.privileges;
      handleUserMenuAuth(menu);
      this.userName = Local.get('username');
      this.menusList = this.$store.state.routesList.routesList;
      // this.menusList = JSON.parse(JSON.stringify(this.menusList));
      this.menusList.find(item => item.id === 'Celelens:UserName').name = this.userName;
      this.menusList.find(item => item.id === 'Celelens:UserName').label = this.userName;
    },
    

    handleSelect(val) {
      if(val === 'Logout') {
        this.$emit('logout');
        return;
      } 

      this.handleRoute('id',val);
      this.findFatherNode('id',val);
      this.$emit('clearGeneCellStudyData');
    },

    // 处理路由跳转
    handleRoute(key,val) {
      const targetNode = findNode(key, this.menusList, val);
      if(targetNode && targetNode.disabled) {
        return;
      }
      if(targetNode && targetNode.routePath) {
        this.$router.push({
          path: targetNode.routePath,
          query: targetNode.query? targetNode.query : {}});
          this.$emit('sendRoute');
      }
    },

    // 查询当前节点的顶级父节点
    findFatherNode(key, val) {
      const nodeArray = findNodePath(key, this.menusList, val);
      if(!nodeArray.length) {
        return;
      }
      const firstNode = nodeArray[0];
      this.menusList.forEach(item => item.active = false);
      this.menusList.find(item => item.id === firstNode.id).active = true;
    },

    initData() {
      this.handleAuth();
      this.menusList = this.$store.state.routesList.routesList;
      this.loadNotifications();
    },

    async loadNotifications() {
      try {
        const serverData =  await getUserUnReadNotifications();
        if(!serverData.data) {
          return;
        }
        const data = serverData.data;
          if(data > 99) {
            this.unReadNotifications = '99+';
          }else if (data > 0){
            this.unReadNotifications = data;
          }else {
            this.unReadNotifications = 0;
          }
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
});