const spatialTranscriptomicsInfos = {
    namespaced: true,
    state: {
        spatialInfos: {},
    },
    mutations: {
        updateSpatialInfos(state, data) {
            state.spatialInfos = JSON.parse(JSON.stringify(data));
        },
    },
    actions: {
        getSpatialInfosFunction({commit}, data) {
            commit('updateSpatialInfos', data);
        },
    },
};
export default spatialTranscriptomicsInfos;