export const PREFIX = 'singleronbio_';
// 2、window.sessionStorage 浏览器临时缓存
export const Session = {
    // 设置临时缓存
    set(key, val) {
        // console.log('tetettetete', key, val)

        window.sessionStorage.setItem(key, JSON.stringify(val));
    },
    // 获取临时缓存
    get(key) {
        return window.sessionStorage.getItem(key);
    },
    // 移除临时缓存
    remove(key) {
        window.sessionStorage.removeItem(key);
    },
    // 移除全部临时缓存
    clear() {
        window.sessionStorage.clear();
    },
};

// 1、window.localStorage 浏览器永久缓存
export const Local = {
    // 设置永久缓存
    set(key, val) {
        key = PREFIX + key;
        window.localStorage.setItem(key, JSON.stringify(val));
    },
    // 获取永久缓存
    get(key) {
        key = PREFIX + key;
        const json = window.localStorage.getItem(key);
        return JSON.parse(json);
    },
    // 移除永久缓存
    remove(key) {
        key = PREFIX + key;
        window.localStorage.removeItem(key);
    },
    // 移除全部永久缓存
    clear() {
        window.localStorage.clear();
    },
};