export function treeFindPath(tree, func, path = []) { // 获取某一节点的所有父节点
    if (!tree) {return [];}
    for (const data of tree) {
        // 这里按照你的需求来存放最后返回的内容吧
        path.push(data.label);
        if (func(data)) {return path;}
        if (data.children) {
            const findChildren = treeFindPath(data.children, func, path);
            if (findChildren.length) {return findChildren;}
        }
        path.pop();
    }
    return [];
}
// //传入参数：需要遍历的json，需要匹配的id
export function findPnodeId(data, id){ // 根据子id查找其直系父id
    //设置结果
    let result;
    if (!data) {
        return;//如果data传空，直接返回
    }
    for (let i = 0; i < data.children.length; i++) {
        const item = data.children[i];
        if (item.id === id) {
            result=data.id;
            //找到id相等的则返回父id
            return result;
        } else if (item.children && item.children.length > 0) {
            //如果有子集，则把子集作为参数重新执行本方法
            result = findPnodeId(item, id);
            //关键，千万不要直接return本方法，不然即使没有返回值也会将返回return，导致最外层循环中断，直接返回undefined,要有返回值才return才对
            if(result){
                return result;
            }
        }
    }
    //如果执行循环中都没有return，则在此return
    return result;
}

// 获取某一节点的所有子节点
export function getChild(nodes, item, arr) { 
    for (const el of nodes) {
        if (el.label === item) {
            // arr.push(el.label);
            if (el.children.length) {
                childNodesDeep(el.children, arr);
            }
        } else if (el.children.length) {
            getChild(el.children, item, arr);
        }
    }
    return arr;
}
export function childNodesDeep(nodes, arr) {
    if (nodes)
        {nodes.forEach((ele) => {
            arr.push(ele.label);
            if (ele.children.length) {
                childNodesDeep(ele.children, arr);
            }
        });}
}

export function toTree(data) {
    // 删除 所有 children,以防止多次调用
    data.forEach(function(item) {
        delete item.children;
    });

    // 将数据存储为 以 id 为 KEY 的 map 索引数据列
    const map = {};
    data.forEach(function(item) {
        map[item.id] = item;
    });
//        console.log(map);
    const val = [];
    data.forEach(function(item) {
        // 以当前遍历项，的pid,去map对象中找到索引的id
        const parent = map[item.pid];
        // 好绕啊，如果找到索引，那么说明此项不在顶级当中,那么需要把此项添加到，他对应的父级中
        if (parent) {
            (parent.children || ( parent.children = [] )).push(item);
        } else {
            //如果没有在map中找到对应的索引ID,那么直接把 当前的item添加到 val结果集中，作为顶级
            val.push(item);
        }
    });
    return val;
}

export function toTree1(options, sampleGroup, ind) {
    const mainChildren = [];
    options.forEach((item, index) => {
        const params = {
            id: item + (index + 100),
            label: item + (index + 100),
            robotId: item,
        };
        const children = [];
        sampleGroup[item]['categories'].forEach((value) => {
            const params1 = {
                id: value + (index + 100),
                label: value + (index + 100),
                robotId: item,
            };
            children.push(params1);
        });
        toResetNaValue(item, children, (index + 100), sampleGroup);
        params['children'] = children;
        mainChildren.push(params);
    });
    return mainChildren;
}

export function toResetNaValue(item, children, index, sampleGroup) {
    if (sampleGroup[item]['has_na']) {
        const params = {
            id: 'NA' + index,
            label: 'NA' + index,
            robotId: item,
        };
        children.push(params);
        return children;
    }
}

/*根据树节点id，返回该节点的所有父节点路径*/
 export function findNodePath(key='id', nodes,targetId){
    for(const node of nodes){
        const simpleNode = {id:node.id, name:node.name, label:node.label};
        if(node[key] === targetId){
            return [simpleNode];
        }
        /*判断该节点是否有子节点*/
        const children = node.children;
        if(children){
            const res = findNodePath(key, children,targetId);
            if(res.length>0){
                return [simpleNode].concat(res);
            }
        }
    }

    return [];
}
//根据目标id，查找树结构中某个节点，并返回该节点
export function findNode(key='id', tree , targetId) {
    for (let i = 0; i < tree.length; i++) {
        const el = tree[i];
        if (el[key] === targetId) {
            return el;
        }
        if (el.children) {
            const newTree = tree.slice(i + 1);
            return findNode(key, [...el.children, ...newTree], targetId);
        }
    }
    return null;
}
//第一个数组是原数组[{label:x,value:y},{label:x,value:x},{}...]，第二个数组是排序数组[x,y,z...]
//第二个数组放在第一个数组前面，如果第一个数组有第二个数组元素就删除
export function processArrays(arr1,arr2) {
   if(!arr1.length || !arr2.length) {
      return [];
   }
   const newArr1 = arr1.filter(el => {
       const isHas = arr2.some(e => e === el.label);
       if(!isHas) {
           return el;
       }
   });
   //修改第二个数组的格式--和第一个数组一样
   const newArr2 = arr2.map(ele => {
       return {
           label: ele,
           value: ele,
       };
   });
   return [...newArr2,...newArr1];
}

// 获取所有子节点的id值
export function lookForAllID(data = [], arr = []) {
    for (const item of data) {
        arr.push(item.id);
        if (item.children && item.children.length) {
            lookForAllID(item.children, arr);
        }
    }
    return arr;
}

// 获取节点id-先序遍历
export function treeForeach(tree, func) {
    tree.forEach(data => {
        func(data);
        data.children && treeForeach(data.children, func); // 遍历子树
    });
}

/**
 * 获取某节点带标记的父节点，返回标记值
 * @param {*} element 
 * @param {*} marker 
 * @returns 
 */
export function findParentWithMarker(element, marker) {
    const list = [];
    while (element.parentElement) {
      if (element.parentElement.hasAttribute(marker)) {
        const markerVal = element.parentElement.getAttribute('marker');
        list.push(markerVal);
      }
      element = element.parentElement;
    }
    return list; // 没有找到带有特殊标记的父节点
}



export function findParentNode(data, key, findVal) {
    if(!data.length) {return;}
    for (let i = 0; i < data.length; i++) {
        const item = data[i];
        if(item[key] === findVal) {
            return item;
        }
        if(item.children && item.children.length) {
            findParentNode(item.children, key, findVal);
        }
    }
}

export function findAllNode(data, key, findVal, result) {
    if(!data.length) {return;}
    for (let i = 0; i < data.length; i++) {
        const item = data[i];
        if(item[key] === findVal) {
            // return item;
            result.push(item);
        }
        if(item.children && item.children.length) {
            findAllNode(item.children, key, findVal, result);
        }
    }
}