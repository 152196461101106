<template>
  <div class="cn-container">
    <p class="a-link">{{ $t('Footer.Phone') }}: 025-58165529</p>
    <p class="a-link">{{ $t('Footer.Email') }}：sessupport@singleronbio.com</p>
    <p class="a-link">
      <span>{{ $t('Footer.Address') }}：</span>
      <span v-html="$t('Footer.Address.Detail')"></span>
    </p>
    <div class="link-icon">
      <a href="https://space.bilibili.com/649707808" target="_blank">
        <img src="@/assets/footer/home_bi.png" alt="">
      </a>
      <el-popover placement="top" trigger="hover">
        <div class="qrcode-item">
          <p>
            <img src="@/assets/footer/qrcodeleft.png" />
            <span>Customer support</span>
          </p>
          <p>
            <img src="@/assets/footer/qrcode.png" />
            <span>Singleron Products</span>
          </p>
        </div>
        <img slot="reference" src="@/assets/footer/home_weixin.png" alt="">
      </el-popover>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUsCn",
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>