const getters = {
    // 判断是否存在
    isHidden: (state) => (id) => {
        const res = state.routesList.hiddenOperList.some(item => item.id === id);
        return res;
    },
    // 判断是否禁止
    isDisabled: (state) => (id) => {
        const res = state.routesList.disabledOperList.some(item => item.id === id);
        return res;
    },
    // 判断是否禁止
    isDisabled1: (state) => (id) => {
        const res = state.routesList.flatRouterList.filter(item => item.id === id);
        return res.length ? res[0].disabled : false;
    },
    lang: state => state.app.lang,
    token: state => state.user.token,
};
export default getters;