
const microApps = [
    {
        name: 'drawing-tools', // 子应用名称
        entry: process.env.VUE_APP_DRAW_TOOL, //子应用运行地址
        // entry: 'http://localhost:5173/', //子应用运行地址
        activeRule: '#/app1',//匹配的路由
        sanbox: true, //解决css冲突
    },
];
 
const apps = microApps.map(item => {
    return {
        ...item,
        container: '#DrawingTools', // 子应用挂载的div
    };
});
 
export default apps;
