import Vue from 'vue';
import App from './App.vue';
import router from '@/router';
import store from '@/store';
import '@/style/scss/common.scss';
import ElementUI from 'element-ui';
import '@/style/theme/index.css';
import '@/svgIcons'; // svgIcons
import i18n from '@/locales/index';
import { globalComponentSize } from '@/utils/componentSize.js';
import vueGlobalInstance from '@/utils/vueGlobalInstance';
import uploader from 'vue-simple-uploader';
import server from "@/server/request";
import MyComponents from "@/components/MyComponents"; // 全局组件注册

import mainApp from './main-app';
import { registerMicroApps, start } from 'qiankun';

Vue.prototype.bus = new Vue();
Vue.use(MyComponents); // 注册全局组件
Vue.config.productionTip = false;
Vue.use(uploader);
Vue.use(ElementUI, { size: globalComponentSize });
server.initProject();

registerMicroApps(mainApp, {
  beforeLoad: app => {
    console.log('before load app.name====>>>>>', app.name);
  },
  beforeMount: [
    app => {
      console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name);
    },
  ],
  afterMount: [
    app => {
      console.log('[LifeCycle] after mount %c%s', 'color: green;', app.name);
    },
  ],
  afterUnmount: [
    app => {
      console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name);
    },
  ],
});
start();
new Vue({
  router,
  store,
  i18n,
  beforeCreate() {
    vueGlobalInstance.set(this);
  },
  render: h => h(App),
}).$mount('#app');
