import { userLogin, userLogout, reg } from "@/api/login";
import server from "@/server/request";
import { _isEmpty} from "@/utils/validate";
// import { removeToken } from '@/utils/auth';
import { Session, Local } from '@/utils/storage';
import {SESSION_TYPE} from "@/const/const";
import { Message } from "element-ui";

const state = {
    token: server.getToken(),
    options: [],
    commentCount: 0,
    currentUsr: {},
};

const mutations = {
    SET_TOKEN: (state1, data) => {
        state1.token = data;
    },
    SET_COMMENT_COUNT: (count) => {
        state.commentCount = count;
    },
    RESET_USR: (usr) => {
        state.currentUsr = usr;
    },
    setToken(state, token) {
        state.token = token;
    },
    removeToken(state) {
        state.token = null;
    },
    // 设置用户信息
    setUserInfo(state, info) {
        // state.currentUsr = info;
        Local.set('user_info', info);
        Local.set('username', info.user_name);
    },
    // 删除用户信息
    removeUserInfo(state) {
        // state.currentUsr = {};
        Local.remove('user_info');
        Local.remove('username');
    },
};

const actions = {
    changeUserToken({ commit }, data) {
        commit('SET_TOKEN', data);
    },
    //用户信息刷新，用于在编辑用户之后及时更新用户信息
    resetUsrInfo({ commit }, usr) {
        commit('RESET_USR', usr);
    },

    async login(context, params) {
        const result = await userLogin(params);
        const data = result.data;
        if(!data) {
            throw result.msg;
        }
        if(data.token) {
            context.commit('setToken', data.token);
            context.commit('setUserInfo', data);
        }
        return result;
    },

    async logout(context) {
        const user_uuid = Local.get('userUuid');
        await userLogout();
        context.commit('removeUserInfo');
        context.commit('removeToken');
        //保存上一个退出用户的uuid
        Local.set('userUuid',user_uuid);

    },
    async reg({commit}, userInfo) {
        const serverdata = await reg(userInfo);
        if(!serverdata.data) {
            throw serverdata.msg;
        }
        if(Number(serverdata.code) === 200) {
            Message.success('Registration successful');
        } else {
            throw 'Registration failed';
        }
        // console.log('sssssss', serverdata);
    },
    // remove token
    resetToken({ commit }) {
        return new Promise(resolve => {
            removeToken();
            resolve();
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};