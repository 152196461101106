import i18nMixin from './i18n-mixin';
export default {
    name: 'SelectLang',
    mixins: [i18nMixin],
    data() {
      return {
        locales:[
            {
                value: 'zh-CN',
                label: '简体中文',
            },
            {
                value: 'en-US',
                label: 'English',
            },
        ],
      };
    },
    methods: {
        handleSelect(val) {
            this.setLang(val);
        },
    },
  };