export const downloadPortalCN = {
    'Download.Portal.Tabs.Name': '数据下载',
    'Download.Portal.No.Requested': '未请求', // 测试
    'Download.Portal.Approved': '请求通过',
    'Download.Portal.Pending': '待审核',
    'Download.Portal.Declined': '请求拒绝',
    'Download.Portal.Expired': '请求过期',
    'Download.Portal.Valid.Time': '有效期',
    'Download.Portal.Days': '日',
    'Download.Portal.Download': '下载',
    'Download.Portal.Request.Download': '请求下载',
    'Download.Portal.Message.Error': '文件不存在',
    'Download.Portal.H5ad.To.Rds.Tips': '*仅显示最新转换结果。有效期为7天，请在有效期内下载。超过期限后，转换结果将自动失效。',
};

export const downloadPortalUS = {
    'Download.Portal.Tabs.Name': 'Download portal',
    'Download.Portal.No.Requested': 'Not requested',
    'Download.Portal.Pending': 'Pending',
    'Download.Portal.Declined': 'Declined',
    'Download.Portal.Expired': 'Expired',
    'Download.Portal.Valid.Time': 'Valid time',
    'Download.Portal.Days': 'Days',
    'Download.Portal.Download': 'Download',
    'Download.Portal.Request.Download': 'Request Download',
    'Download.Portal.Message.Error': 'file does not exist',
    'Download.Portal.H5ad.To.Rds.Tips': '*Only the latest RDS data is shown, valid for 7 days. Please download within this period as the data will expire automatically.',
};