export const subsetCN = {
    'Subset.Merge.Title': '合并clusters',
    'Subset.Merge.Name': 'Cluster名称',
    'Subset.Merge.Name.Placeholder': '输入cluster名称',
    'Subset.Merge.Target.Name': '合并至一个新subset或当前subset',
    'Subset.Merge.Target.Placeholder': '输入或选择一个subset',
    'Subset.Merge.Marked': '未选择的cluster标记为',
    'Subset.Merge.Current.Labels': '当前标签',

    'Subset.Copy.Title': '复制subset',
    'Subset.Copy.Name': 'Subset名称',
    'Subset.Copy.Name.Placeholder': '输入subset名称',
    'Subset.Delete.Tip': '该subset删除后无法恢复',
    'Subset.Refresh.Tip': '注意：提交操作后将刷新页面。请确保您已保存分析结果。已提交至后台的任务不受影响。',
    'Subset.Calculate.Tip': '点击计算以运行该subset的cluster DEGs。如果生成新的cluster或计算失败，需要重新计算。计算完成后，需要手动刷新页面才能显示结果。',
    'Subset.Calculate.Title': '计算差异基因',
    'Subset.Calculate.Error.Title': 'cluster DEGs计算失败,请重新尝试。',
    'Subset.check.release': '当前Subset存在关联的子任务,因此无法删除或重命名。',

    'Subset.Calculate.Running.Tips': '其他计算正在进行中，请稍等.',

    'Subset.Map.Cell.Labels': '映射细胞标签',
    'Subset.Map.Tip': '注意：先检查该子集细胞与下拉选择任务细胞的交集，然后将下拉选择任务中的所选细胞标签映射到与该子集的交集细胞上。',
    'Subset.Task.Name': '任务名称',
    'Subset.Select.Task': '选择一个任务',
    'Subset.Select.Subset': '从已选任务中选择一个subset',
    'Subset.Cell.Labels': '细胞标签被映射为',
    'Subset.Select.Clusters': '从已选subset中选择clusters',
};
export const subsetUS = {
    'Subset.Merge.Title': 'Merge clusters',
    'Subset.Merge.Name': 'Cluster name',
    'Subset.Merge.Name.Placeholder': 'Type in cluster name',
    'Subset.Merge.Target.Name': 'Merge to a new or existing subset',
    'Subset.Merge.Target.Placeholder': 'Type in or select a subset',
    'Subset.Merge.Marked': 'The unselected clusters marked as',
    'Subset.Merge.Current.Labels': 'current labels',

    'Subset.Copy.Title': 'Copy subset',
    'Subset.Copy.Name': 'Subset name',
    'Subset.Copy.Name.Placeholder': 'type in subset name',
    'Subset.Delete.Tip': 'This subset will be deleted permanently',
    'Subset.Refresh.Tip': 'Note: The page will be refreshed after each operation. Please make sure you have saved your analysis results. Tasks submitted to the backend will not be affected. ',
    'Subset.Calculate.Tip': 'Click Calculate to run cluster DEGs of this subset. If merging generates a new cluster or calculation failed, recalculation is required. After calculation, manual refreshing is required to show the results. ',
    'Subset.Calculate.Title': 'Calculate cluster DEGs',
    'Subset.Calculate.Error.Title': 'Cluster DEGs calculation failed, please try again.',
    'Subset.check.release': 'The subset has subtasks, so it cannot be deleted or renamed.',
    'Subset.Calculate.Running.Tips': 'Other calculation is in progress. Please wait a moment',
    'Subset.Map.Cell.Labels': 'Map cell labels',
    "Subset.Map.Tip": 'Note: Cells of this subset will review the intersection with the cells of the dropdown-selected task, and selected cell labels from the dropdown-selected task will be mapped to the intersecting cells in this subset. ',
    'Subset.Task.Name': 'Task Name',
    'Subset.Select.Task': 'Select a task',
    'Subset.Select.Subset': 'Select a subset from the chosen task',
    'Subset.Cell.Labels': 'Cell labels mapped by',
    'Subset.Select.Clusters': 'Select clusters from the chosen subset',
};