
const routesListModule = {
	namespaced: true,
	state: {
		routesList: [],
		activePath: '',
		hiddenOperList: [],
		disabledOperList: [],
		flatRouterList: [], // 原始路由
	},
	mutations: {
		// 设置路由，菜单中使用到
		getRoutesList(state, data) {
			state.routesList = data;
		},
		changeRoutePathM(state, data) {
            state.activePath = data;
        },
		getDisabledOperListM(state, data) {
			state.disabledOperList = data;
		},
		getHiddenOperListM(state, data) {
			state.hiddenOperList = data;
		},
		setFlatRouterList(state, data) {
			state.flatRouterList = data;
		},
	},
	actions: {
		// 设置路由，菜单中使用到
		setRoutesList({ commit }, data) {
			commit('getRoutesList', data);
		},

		changeRoutePathA({ commit }, data) {
			commit('changeRoutePathM', data);
		},
		// 设置禁用操作按钮列表
		setDisabledOperListA({ commit }, data) {
			commit('getDisabledOperListM', data);
		},
		// 设置显隐操作按钮列表
		setHiddenOperListA({ commit }, data) {
			commit('getHiddenOperListM', data);
		},
		setFlatRouterListByUser( { commit }, data) {
			commit('setFlatRouterList', data);
		},
	},
};

export default routesListModule;
