export const cellInteractionCN = {
    'Cell.Interaction.Explore.Title': '细胞相互作用',

    'Cell.Interaction.Result.Tar.Download': 'result.tar 下载',

    'Cell.Interaction.Selected.Cell.Type': '选定细胞类型互作关系',
    'Cell.interaction.Top.Interacting.Pairs': 'Top 30互作对',
    'Cell.Interaction.Selected.Cell.Tips': '细胞类型之间的相互作用网络，其中节点代表细胞类型，线的粗细与交叉对的数量成正比，线的颜色与配体细胞类型一致。 ',

    'Cell.Interaction.Visualize.Interaction.Pairs': '互作对可视化',
    'Cell.Interaction.Interacting.Pair': '互作对*: ',
    'Cell.Interaction.Pair.Upload.Name': '上传基因特征',

    'Cell.Interaction.Interacting.Cell': '互作细胞类型*: ',
    'Cell.Interaction.Cell.Upload.Name': '上传细胞类型',
    'Cell.Interaction.Cell.Placeholder': '选择细胞类型',

    'Cell.Interaction.P.Value.Threshold': 'P value阈值*: ',

    'Cell.Interaction.Rank.Value.Threshold': 'Rank值阈值*: ',
    'Cell.Interaction.Select.From.Cytokine': "选择细胞因子",
    'Cell.Interaction.Select.Cell.Type': '选择细胞类型',

    'Cell.Interaction.View.Name': 'view name',

    'Cell.Interaction.Table.Interacting.Cell': 'interacting_cell',
    'Cell.Interacting.Table.Interacting.Pair': 'interacting_pair',
    'Cell.Interacting.Table.Secreted': 'Secreted',
    'Cell.Interacting.Table.Receptor_a': 'Receptor_a',
    'Cell.Interacting.Table.Receptor_b': 'Receptor_b',
    'Cell.Interacting.Table.Means': 'Means',
    'Cell.Interacting.Table.P.Values': 'Pvalues',
    'Cell.Interacting.Table.Rank.Value': 'RankValue',

    'Cell.Interacting.Upload.Gene.Signature': '*选择基因特征文件。每个文件包含一个基因特征。文件中每行包含一个基因。',
    'Cell.Interacting.Upload.Cell.Types': '*选择细胞类型文件。每个文件包含一个细胞类型列表。文件中每行包含一个细胞类型。',

};

export const cellInteractionUS = {
    'Cell.Interaction.Explore.Title': 'Explore Cell Interactions',

    'Cell.Interaction.Result.Tar.Download': 'result.tar download',

    'Cell.Interaction.Selected.Cell.Type': 'Interactions of all selected cell types',
    'Cell.interaction.Top.Interacting.Pairs': 'Top 30 interacting pairs',
    'Cell.Interaction.Selected.Cell.Tips': 'Interaction network between cell types, in which nodes represent cell types, edge thickness is proportional to the number of intersection pairs, and line color is consistent with ligand cell type. ',

    'Cell.Interaction.Visualize.Interaction.Pairs': 'Visualize interacting pairs',
    'Cell.Interaction.Interacting.Pair': 'Interacting pair*: ',
    'Cell.Interaction.Pair.Upload.Name': 'Upload gene signature',

    'Cell.Interaction.Interacting.Cell': 'Interacting cell*: ',
    'Cell.Interaction.Cell.Upload.Name': 'Upload cell types',
    'Cell.Interaction.Cell.Placeholder': 'Select cell types',

    'Cell.Interaction.P.Value.Threshold': 'P value threshold*: ',

    'Cell.Interaction.Rank.Value.Threshold': 'Rank value threshold*: ',
    'Cell.Interaction.Select.From.Cytokine': "Select from Cytokine",
    'Cell.Interaction.Select.Cell.Type': 'Select cell types',

    'Cell.Interaction.View.Name': 'view name',

    'Cell.Interaction.Table.Interacting.Cell': 'interacting_cell',
    'Cell.Interacting.Table.Interacting.Pair': 'interacting_pair',
    'Cell.Interacting.Table.Secreted': 'Secreted',
    'Cell.Interacting.Table.Receptor_a': 'Receptor_a',
    'Cell.Interacting.Table.Receptor_b': 'Receptor_b',
    'Cell.Interacting.Table.Means': 'Means',
    'Cell.Interacting.Table.P.Values': 'Pvalues',
    'Cell.Interacting.Table.Rank.Value': 'RankValue',

    'Cell.Interacting.Upload.Gene.Signature': '*Select gene signature(.csv). Each file contains one gene signature. Each line in file contains a single gene.',
    'Cell.Interacting.Upload.Cell.Types': '*Select cell type list(.csv). Each file contains one cell type list. Each line in file contains a single cell type.',
};