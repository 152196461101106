export const DifferentialExpressionCN = {
    'Differential.Expression.Explore.Title': '差异表达分析',
    'Differential.Expression.Top.Deg.Expression': '查看TOP 差异基因在细胞类型中的表达',
    'Differential.Expression.Compute.Deg': '计算两组间差异基因',
    'Differential.Expression.All.Degs': '所有差异基因',
    'Differential.Expression.Deg.List.Download': '未过滤差异基因列表',

    'Differential.Expression.Significant.Degs': '显著差异基因',
    'Differential.Expression.Significant.Degs.Tips': 'LogFC大于1, 在10%以上的细胞中表达，且adjusted p value小于等于0.05的差异基因被认为是显著的差异基因。过滤后的显著DEGs被导入clusterProfiler（ver 4.2.0）和GeneOntology、Reactome和Wikipathway的参考数据库进行通路富集分析。',
    'Differential.Expression.Top.Up.Regulated.Genes': 'Top 10上调基因',
    'Differential.Expression.Top.Down.Regulated.Genes': 'Top 10下调基因',
    'Differential.Expression.No.Significant.Degs': '没有显著差异基因',

    'Differential.Expression.Volcano.Plot': 'Volcano plot',
    'Differential.Expression.Show.Top.Genes': '展示Top基因',

    'Differential.Expression.Filters.Fro.Degs.Analysis': '设置通路富集分析中的差异基因过滤参数',

    'Differential.Expression.Gene.Ontology': 'Gene ontology',

    'Differential.Expression.Pathway.Enrichment': '通路富集',
    'Differential.Expression.Download.Table': '下载表格',
    'Differential.Expression.Gene.On.Pathway': '通路中基因表达',

    'Differential.Expression.Bar.Go.Tips': '颜色对应于功能部分（BP：biological process，CC：cellular component，MF：molecular function）。功能通路位于纵轴上，注释到该通路上的差异基因数与差异基因总数的比值在横轴上。',
    'Differential.Expression.Bar.Reactome.Tips': '功能通路位于纵轴上，注释到该通路上的差异基因数与差异基因总数的比值在横轴上。',
    'Differential.Expression.Bar.WikiPathways.Tips': '功能通路位于纵轴上，注释到该通路上的差异基因数与差异基因总数的比值在横轴上。',
    'Differential.Expression.Bar.KEGG.Tips': '功能通路位于纵轴上，注释到该通路上的差异基因数与差异基因总数的比值在横轴上。',

    'Differential.Expression.Dot.Go.Tips': '功能通路位于纵轴上，被注释到GO的差异表达基因与所有DEGs的比率则在横轴上。点的大小反映基因数量。点的颜色对应于通路富集的统计显著性。',
    'Differential.Expression.Dot.Reactome.Tips': '功能通路位于纵轴上，被注释到Reactome的差异表达基因与所有DEGs的比率则在横轴上。点的大小反映基因数量。点的颜色对应于通路富集的统计显著性。',
    'Differential.Expression.Dot.WikiPathways.Tips': '功能通路位于纵轴上，被注释到WikiPathways的差异表达基因与所有DEGs的比率则在横轴上。点的大小反映基因数量。点的颜色对应于通路富集的统计显著性。',
    'Differential.Expression.Dot.Tips': '功能通路位于纵轴上，注释到该通路上的差异基因数与差异基因总数的比值在横轴上。点的大小反映基因数量。点的颜色对应于通路富集的统计显著性。',

    'Differential.Expression.Gene.Count': 'Gene Count',
    'Differential.Expression.Genes': 'Genes',

    'Different.Expression.Type': '类型',
    'Different.Expression.Normal': '常规',
    'Different.Expression.Two.Groups': '批量处理(双分组)',
    'Different.Expression.One.Group': '批量处理(单分组)',
    'Different.Expression.Two.Group.Title': '计算两个选定细胞组间每种细胞类型的差异基因',
    'Different.Expression.One.Group.Title': '计算所选组中每种细胞类型与其他剩余细胞的差异基因',

    'Different.Expression.Choose.Cell.Type': '选择2个分组包含的{cell}',
    'Different.Expression.Choose.Cell.Type.Tips': '每个所选细胞类型将计算两个所选分组间的差异基因，并为每种细胞类型生成一个单独任务。',
    'Different.Expression.Choose.Cell.Type.Btn.Click.Tips': '请先选择分组。',

    'Different.Expression.One.Group.Sample.Group': '选择',
    'Different.Expression.Choose.One.Group.Cell.Type': '选择 {cell}',
    'Different.Expression.Choose.One.Group.Cell.Type.Tips': '所选分组内，每个所选细胞类型将计算与其他剩余细胞之间的差异基因，并为每种细胞类型生成一个单独任务。',
    'Different.Expression.Choose.One.Group.All.Other.Cell': 'All other cells',
    'Different.Expression.DEG.Lists': '差异基因列表',

};

export const DifferentialExpressionUS = {
    'Differential.Expression.Explore.Title': 'Explore Differential Expression',
    'Differential.Expression.Top.Deg.Expression': 'View top DEG expression in cell types',
    'Differential.Expression.Compute.Deg': 'Compute DEGs between selected groups',
    'Differential.Expression.All.Degs': 'All DEGs',
    'Differential.Expression.Deg.List.Download': 'Unfiltered ranked DEG list download',

    'Differential.Expression.Significant.Degs': 'Significant DEGs',
    'Differential.Expression.Significant.Degs.Tips': 'DEGs with log transformed fold change > 1, expressed in more than 10% of the cells, and with adjusted p value <= 0.05 are considered significant. The significant DEGs after filtering are piped into pathway enrichment analysis using clusterProfiler(version 4.2.0) and reference databases of Gene Ontology, Reactome and Wikipathway.',
    'Differential.Expression.Top.Up.Regulated.Genes': 'Top 10 upregulated genes',
    'Differential.Expression.Top.Down.Regulated.Genes': 'Top 10 downregulated genes',
    'Differential.Expression.No.Significant.Degs': 'No significant DEGs',

    'Differential.Expression.Volcano.Plot': 'Volcano plot',
    'Differential.Expression.Show.Top.Genes': 'Show Top Genes',

    'Differential.Expression.Filters.Fro.Degs.Analysis': 'Set filters for DEGs used in pathway enrichment analysis',

    'Differential.Expression.Gene.Ontology': 'Gene ontology',

    'Differential.Expression.Pathway.Enrichment': 'Pathway enrichment',
    'Differential.Expression.Download.Table': 'Download table',
    'Differential.Expression.Gene.On.Pathway': 'Expression of genes on the pathway',

    'Differential.Expression.Bar.Go.Tips': 'Colors corresponds to functional components (biological process, cellular component, and molecular function). Functional pathways are on the vertical axis, and the ratio of DEGs annotated to the pathway to all DEGs is plotted on the horizontal axis.',
    'Differential.Expression.Bar.Reactome.Tips': 'Functional pathways are on the vertical axis, and the ratio of DEGs annotated to the pathway to all DEGs is plotted on the horizontal axis.',
    'Differential.Expression.Bar.WikiPathways.Tips': 'Functional pathways are on the vertical axis, and the ratio of DEGs annotated to the pathway to all DEGs is plotted on the horizontal axis.',
    'Differential.Expression.Bar.KEGG.Tips': 'Functional pathways are on the vertical axis, and the ratio of DEGs annotated to the pathway to all DEGs is plotted on the horizontal axis.',

    'Differential.Expression.Dot.Go.Tips': 'Functional pathways are on the vertical axis, and the ratio of DEGs annotated to GO terms to all DEGs is plotted on the horizontal axis. Size of the dot reflects number of genes. Color of the dot corresponds to statistical significance of the enrichment of the pathway, as explained by the figure label.',
    'Differential.Expression.Dot.Reactome.Tips': 'Functional pathways are on the vertical axis, and the ratio of DEGs annotated to Reactome terms to all DEGs is plotted on the horizontal axis. Size of the dot reflects number of genes. Color of the dot corresponds to statistical significance of the enrichment of the pathway, as explained by the figure label.',
    'Differential.Expression.Dot.WikiPathways.Tips': 'Functional pathways are on the vertical axis, and the ratio of DEGs annotated to Wiki Pathway terms to all DEGs is plotted on the horizontal axis. Size of the dot reflects number of genes. Color of the dot corresponds to statistical significance of the enrichment of the pathway, as explained by the figure label.',
    'Differential.Expression.Dot.Tips': 'Functional pathways are on the vertical axis, and the ratio of DEGs annotated to the pathway to all DEGs is plotted on the horizontal axis. Size of the dot reflects number of genes. Color of the dot corresponds to statistical significance of the enrichment of the pathway, as explained by the figure label.',

    'Differential.Expression.Gene.Count': 'Gene Count',
    'Differential.Expression.Genes': 'Genes',

    'Different.Expression.Type': 'Type',
    'Different.Expression.Normal': 'Normal',
    'Different.Expression.Two.Groups': 'Batch processing(two groups)',
    'Different.Expression.One.Group': 'Batch processing(one group)',
    'Different.Expression.Two.Group.Title': 'Compute DEGs of each cell types between two selected groups',
    'Different.Expression.One.Group.Title': 'Compute DEGs of the selected group between each cell types and the rest cells',

    'Different.Expression.Choose.Cell.Type': 'Choose  {cell}  from 2 groups',
    'Different.Expression.Choose.Cell.Type.Tips': 'Each selected cell type will compute DEGs between the two chosen groups and generate a separate task for each cell type.',
    'Different.Expression.Choose.Cell.Type.Btn.Click.Tips': 'Please select the group first。',

    'Different.Expression.One.Group.Sample.Group': 'Choose a ',
    'Different.Expression.Choose.One.Group.Cell.Type': 'Select {cell}',
    'Different.Expression.Choose.One.Group.Cell.Type.Tips': 'In the selected group, it will compute DEGs between each selected cell type and the rest cells, and generate a separate task for each cell type.',
    'Different.Expression.Choose.One.Group.All.Other.Cell': 'All other cells',

    'Different.Expression.DEG.Lists': 'DEG lists',
};