export const exploreTabsNameCN = {
    'ExploreTabsName.Explore': '探索',
    'ExploreTabsName.Cell.Quality': '细胞质控',
    'ExploreTabsName.Gene.Expression': '基因表达',
    'ExploreTabsName.Cellular.Composition': '细胞占比',
    'ExploreTabsName.Differential.Expression': '差异表达分析',
    'ExploreTabsName.Cell.Interactions': '细胞相互作用',
    'ExploreTabsName.Trajectory.Analysis': '轨迹分析',
    'ExploreTabsName.CNV.Analysis': '拷贝数变化和肿瘤异质性',
    'ExploreTabsName.Cell.Annotations': '细胞注释',
    'ExploreTabsName.ITH.Analysis': 'ITH分析',
    'ExploreTabsName.CNV.Analysis1': 'CNV分析',

};
export const exploreTabsNameUS = {
    'ExploreTabsName.Explore': 'Explore',
    'ExploreTabsName.Cell.Quality': 'Cell Quality',
    'ExploreTabsName.Gene.Expression': 'Gene Expression',
    'ExploreTabsName.Cellular.Composition': 'Cellular Composition',
    'ExploreTabsName.Differential.Expression': 'Differential Expression',
    'ExploreTabsName.Cell.Interactions': 'Cell Interactions',
    'ExploreTabsName.Trajectory.Analysis': 'Trajectory Analysis',
    'ExploreTabsName.CNV.Analysis': 'CNV Analysis',
    'ExploreTabsName.Cell.Annotations': 'Cell Annotations',
    'ExploreTabsName.ITH.Analysis': 'ITH Analysis',
    'ExploreTabsName.CNV.Analysis1': 'CNV Analysis',
};