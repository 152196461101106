import axios from 'axios';
import { Message } from 'element-ui';
import { PrevLoading } from "@/utils/load/loading";
import server from "@/server/request";

const infoTip = msg => {
  Message({
    message: msg,
    type: 'info',
    duration: 1000,
  });
};
const responseData =  {
    data: {
            data: null,
    },
};
const statusCode = [401, 503, 500];


const errorTip = msg => {    
  Message({
    message: msg,
    type: 'error',
    duration: 1000,
  });
};


// 创建axios实例
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  //设置请求超时 ,例如超过了10s，就会告知用户当前请求超时，请刷新等。
  timeout: 600000,
  crossDomain: true,
});



//创建请求拦截
instance.interceptors.request.use(
  (config) => {
    // 如果开启 token 认证
    // if (serverConfig.useTokenAuthorization) {
    //   config.headers["Authorization"] = localStorage.getItem("token"); // 请求头携带 token
    // }
    // // 设置请求头
    // if(!config.headers["content-type"]) { // 如果没有设置请求头
    //   if(config.method === 'post') {
    //     config.headers["content-type"] = "application/x-www-form-urlencoded"; // post 请求
    //     config.data = qs.stringify(config.data); // 序列化,比如表单数据
    //   } else {
    //     config.headers["content-type"] = "application/json"; // 默认类型
    //   }
    // }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);


instance.interceptors.response.use(    
  response => {   
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据     
    // 否则的话抛出错误
      PrevLoading.done();
    if (response.status === 200) {
      return Promise.resolve(response);        
    }
    if(response.status === 401) {
        server.authTip();
    }
      return Promise.reject(response);        
          
  },    
  // 服务器状态码不是2开头的的情况
  // 这里可以跟你们的后台开发人员协商好统一的错误状态码    
  // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
  // 下面列举几个常见的操作，其他需求可自行扩展
  error => {
      PrevLoading.done();
      if(error.message === 'Network Error') {
          server.serverCloseTip();
          responseData.data.msg = error.message;
          return Promise.resolve(responseData);
      }
      if (error.response.status) {            
          switch (error.response.status) {                
              // 401: 未登录
              // 未登录则跳转登录页面，并携带当前页面的路径
              // 在登录成功后返回当前页面，这一步需要在登录页操作。
              case 503:
                  server.serverCloseTip();
                  break;
              case 500:
                  server.serverCloseTip();
                  break;
              case 401:
                  server.authTip();
                  break;           
              case 403:
                  infoTip('Resource Unavailable');
                  // 清除token
                  // localStorage.removeItem('token');
                  break; 

              // 404请求不存在
              case 404:
                  infoTip('Resource does not exist');
                  break;
              // 其他错误，直接抛出错误提示
              default:
                  errorTip(error.response.data.msg);
          }
          if(statusCode.includes(error.response?.status)) {
              responseData.data.msg = error.message;
              return Promise.resolve(responseData);
          }
          return Promise.reject(error);
      }
  }, 
);

export default instance;
