import { toCeil } from "@/utils";

export function toMinutes(second) {
    const min = second / 1000 / 60;
    if (min >= 0 && min < 1) {
        return ''
    } else {
        const minV = min.toFixed(0);
        return Math.abs(minV) + 'Min';
    }
}

export function toHours(second) {
    // let hours = (second % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60);
    let hours = second / (1000 * 60 * 60);
    hours = toCeil(hours, 2);
    return hours + 'h';
    // const min = second / 1000;
    // if (min >= 0 && min < 1) {
    //     return '';
    // } else {
    //     const minV = min.toFixed(0);
    //     return Math.abs(minV) + 'h';
    // }
}

export function subTime(a, b) {
    const interVal = new Date(a) - new Date(b)
    return interVal
}

/**
 * 表格时间格式化
 */
export function formatDate(cellValue, isHasSecond = true) {
    if (cellValue === null || cellValue === '') {return '';}
    const date = new Date(cellValue);
    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ?
        '0' + (date.getMonth() + 1) :
        date.getMonth() + 1;
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const minutes = date.getMinutes() < 10 ?
        '0' + date.getMinutes() :
        date.getMinutes();
    const seconds = date.getSeconds() < 10 ?
        '0' + date.getSeconds() :
        date.getSeconds();
    if(!isHasSecond) {
        return year + '-' + month + '-' + day;
    }
    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' +
        seconds;
}


export function parseTime(time, cFormat) {
    if (arguments.length === 0) {
        return null;
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
    let date;
    if (typeof time === 'object') {
        date = time;
    } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
            time = parseInt(time);
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
            time = time * 1000;
        }
        date = new Date(time);
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
    };
    const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
        const value = formatObj[key];
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
            return ['日', '一', '二', '三', '四', '五', '六'][value];
        }
        return value.toString().padStart(2, '0');
    });
    return time_str;
}

export const throttle = (fn, delay = 1000) => {
    //距离上一次的执行时间
    let lastTime = 0;
    return function() {
        const _this = this;
        const _arguments = arguments;
        const now = new Date().getTime();
        //如果距离上一次执行超过了delay才能再次执行
        if(now - lastTime > delay){
            fn.apply(_this,_arguments);
            lastTime = now;
        }
    };
};