<template>
  <div class="update-log-container">
    <img src="@/assets/nav/question_mark.png" alt="" @click="handleDrawer">
    <el-drawer
        title=""
        direction="rtl"
        :visible.sync="showDrawer"
        :modal="false"
        :size="350">
      <div class="feedback-wrapper sub-title" slot="title">
          <img src="@/assets/nav/Feedback.png" alt="" @click="toContact">
          <h3 @click="toContact">{{ $t('User.Feedback') }}</h3>
          <img @click="toContact" src="@/assets/nav/arrow.png" alt="">
      </div>
      <div class="log-wrapper">
        <div class="sub-title log-title">
          <img src="@/assets/nav/uodateLog.png" alt="">
          <h3>{{ $t('User.Update.Log') }}</h3>
        </div>
        <ul class="infinite-list" v-infinite-scroll="loadData" :infinite-scroll-disabled="loadDisabled">
          <li v-for="(item, index) in updateLogData" :key="index" class="infinite-list-item">
            <p class="date"> {{ item.logdate | formatDateFn }}</p>
            <p class="editor-content-view" v-html="item.info"></p>
          </li>
        </ul>
        <p v-if="loading" class="loading-wrapper">{{ $t('Data.Analysis.Loading') }}...</p>
<!--        <p v-if="noMore" class="loading-wrapper"></p>-->
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getUpdateLogData } from "@/api/resource";
import { formatDate } from "@/utils/time/time";

export default {
  name: "logAndFeedBackComp",
  data() {
    return {
      showDrawer: false,
      updateLogData: [],
      total: 0,
      query: {
        pageNum: 0,
        pageSize: 10,
      },
      loading: false, // 加载中
      totalCount: 0, // 总页数
      currentCount: 0, // 当前页数
    };
  },
  mounted() {

  },
  filters: {
    formatDateFn(val) {
      if(!val) {return '';}
      return formatDate(val, false);
      // const date = new Date(val);
      // return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
     },
  },
  computed: {
    noMore() {
      return this.currentCount >= this.totalCount - 1;
    },
    loadDisabled() {
      return this.loading || this.noMore;
    },
  },
  methods: {
    closeDrawer() {
      this.showDrawer = false;
    },
    handleDrawer() {
      this.showDrawer = !this.showDrawer;
      if(this.updateLogData.length) {return;}
      this.initData();
    },
    toContact() {
      this.showDrawer = false;
      this.$router.push('/contact');
    },
    async initData() {
      this.totalCount = 0;
      this.currentCount = 0;
      const data = await this.getUpdateLogData();
      if(!data) {return;}
      this.total = data.total;
      this.updateLogData = data.rows ? data.rows : [];
      this.requestCount();
    },
    async loadData() {
      this.currentCount++;
      this.loading = true;
      this.query.pageNum++;
      const data = await this.getUpdateLogData();
      if(!data) {return;}
      this.updateLogData = [...this.updateLogData, ...data.rows];
    },
    async getUpdateLogData() {
      const serverdata = await getUpdateLogData(this.query);
      this.loading = false;
      const data = serverdata.data;
      if(!data) {return;}
      const rows = data.rows ? data.rows : [];
      rows.forEach(item => {
        item.info = item.info.split('&nbsp;').join(' ').replace(/style\s*=\s*"[^"]*"|style\s*=\s*'[^']*'/gi, '');
      });
      data.rows = rows;
      return data;
    },
    // 总共需要请求多少次
    requestCount() {
      this.totalCount = Math.ceil(this.total / this.query.pageSize);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>