export const monocleCN = {
    'Monocle.Explore.Title': '轨迹分析',
    'Monocle.Choose.Monocle.Version': '选择Monocle版本',

    // 2
    'Monocle.Reverse.Pseudotime.Label': '反转拟时间',
    'Monocle.Reverse.Pseudotime.Tips': '选择是否反转生物过程的起点和终点。',
    'Monocle.Show.Tree.Label': '展示树状结构',
    'Monocle.Show.Tree.Tips': '是否显示在最小生成树中的细胞的连接关系',
    'Monocle.Show.Branch.Point.Label': '展示分支点',
    'Monocle.Show.Branch.Point': '是否显示每个分支点的图标',
    'Monocle.Specify.Root.Label': '指定起点',
    'Monocle.Specify.Root.Tips': '指定细胞类型/cluster作为起点',
    'Monocle.Trajectory': '轨迹分析', //Trajectory
    'Monocle.Facet': '轨迹分类',
    'Monocle.Download.Result.Files': '下载轨迹分析文件',
    'Monocle.Density.Stream': 'Density stream 密度流',
    'Monocle.Variable.Gene.Module.Expression': '可变基因模块表达情况',
    'Monocle.Download,Variable.Result.Files': '下载可变基因结果文件',
    'Monocle.Top.Eight.Pseudotime.Genes': 'Top 8高可变基因',
    'Monocle.Downstream.Title': 'Monocle2 轨迹下游分析',
    'Monocle.Downstream.Analysis.Type': '分析',
    'Monocle.Type.In.Genes': '输入基因',
    'Monocle.Target.Gene.Expression.In.Pseudotime': '指定基因表达轨迹映射',
    'Monocle.Trajectory.State.Expression': '分支表达情况',
    'Monocle.Branch.Split': '拆分分支点', 
    'Monocle.Downstream.Analysis.Type.Tips': '*点击切换分析名称，可提交并查看对应的已提交任务列表。',
    'Monocle.Branch.Split.Tips': '分支点可以在上方轨迹分类的Facet state图中查看。',

    // 3
    'Monocle.Root.Selection': '指定root',
    'Monocle.Nodelist': '节点列表',
    'Monocle.Fina.Variable.Genes': '寻找可变基因',
    'Monocle.Variable.Nodelist': '节点列表: ',
    'Monocle.Variable.Partitions': '分区: ',
    'Monocle.Variable.Resolution': '分辨率: ',
    'Monocle.Variable.Q.Value.Threshold': 'Q value阈值: ',
    'Monocle.Gene.Module.Expression.Heatmap': '基因模块表达heatmap',
    'Monocle.Variable.Gene.Table': '可变基因表',
    'Monocle.Variable.Gene.Table.Download': '下载可变基因结果文件',
    'Monocle.Selected.Gene.Across.Pseudotime': '在伪时间轴上选定可变基因的表达变化',
    'Monocle.Gene.Expression.Changes.Across.Pseudotime': '在伪时间轴上基因表达变化',

    'Monocle.Variable.Nodelist.Tips': '节点列表：指定root用于生成伪时间结果，以查找可变基因。 ',
    "Monocle.Variable.Partitions.Tips": '查找确定分区细胞的可变基因。默认值为所有分区。',
    'Monocle.Variable.Resolution.Tips': '将可变基因分组成模块的分辨率。较高的分辨率回产生更多的模块。默认值为0.001.',
    'Monocle.Variable.Q.Value.Tips': '过滤可变基因的Q value，默认值为0.05。',

    'Monocle.Table.Id': 'Id',
    'Monocle.Table.Gene.Short.Name': 'Gene_short_name',
    "Monocle.Table.P.value": 'P_value',
    'Monocle.Table.Morans.Test.Statistic': 'Morans_test_statistic',
    'Monocle.Table.Morans.Imorans.I': 'Morans_Imorans_I',
    'Monocle.Table.Status': 'Status',
    'Monocle.Table.Module': 'Module',
    "Monocle.Table.Supermodule": 'Supermodule',
    "Monocle.Task.Submit": '已提交',
    "Monocle.Task.Error.Submit": ' 任务无法提交',

    "Monocle.SubTitle.General.Parameters": '通用参数', // ：
    "Monocle.Method.Selecting.Genes": '挑选高可变方法',
    "Monocle.Method.Selecting.Genes.Tips": 'seurat方法是通过Seurat软件的FindVairableFeatures函数寻找到的指定数量的高变基因来用于轨迹构建。 persion方法是通过monocle软件的dispersionTable函数寻找到的差异基因，筛选平均表达量大于0.5的基因来用于轨迹构建。',
    'Monocle.SubTitle.Extra.Parameters': '其他参数',
    'Monocle3.Params.Reduction': '重新降维',
    'Monocle3.Params.KNN.Value': 'KNN值',
};

export const monocleUS = {
    "Monocle.SubTitle.General.Parameters": 'General Parameters',
    "Monocle.Method.Selecting.Genes": 'Method of selecting highly variable genes',
    "Monocle.Method.Selecting.Genes.Tips": 'The Seurat method identifies a specified number of highly variable genes for trajectory construction using the FindVariableFeatures function in the Seurat software. The persion method utilizes the dispersionTable function in the Monocle software to identify differential genes, filtering for genes with an average expression level greater than 0.5 for trajectory construction.',
    'Monocle.SubTitle.Extra.Parameters': 'Extra Parameters',
    'Monocle3.Params.Reduction': 'Reduction',
    'Monocle3.Params.KNN.Value': 'KNN value',

    'Monocle.Explore.Title': 'Explore Trajectory Analysis',
    'Monocle.Choose.Monocle.Version': 'Choose Monocle version',

    // 2
    'Monocle.Reverse.Pseudotime.Label': 'Reverse pseudotime',
    'Monocle.Reverse.Pseudotime.Tips': 'whether to reverse the beginning and end points of the learned biological process',
    'Monocle.Show.Tree.Label': 'Show tree',
    'Monocle.Show.Tree.Tips': 'whether to show the links between cells connected in the minimum spanning tree',
    'Monocle.Show.Branch.Point.Label': 'Show branch point',
    'Monocle.Show.Branch.Point': 'whether to show icons for each branch point',
    'Monocle.Specify.Root.Label': 'Specify root cluster',
    'Monocle.Specify.Root.Tips': 'specify a cell type/cluster as the start point',
    'Monocle.Trajectory': 'Trajectory', //Trajectory
    'Monocle.Facet': 'Facet',
    'Monocle.Download.Result.Files': 'Download trajectory result files',
    'Monocle.Density.Stream': 'Density stream',
    'Monocle.Variable.Gene.Module.Expression': 'Variable gene module expression',
    'Monocle.Download,Variable.Result.Files': 'Download variable genes result files',
    'Monocle.Top.Eight.Pseudotime.Genes': 'Top 8 pseudotime genes',
    'Monocle.Downstream.Title': 'Monocle2 Trajectory downstream analysis',
    'Monocle.Downstream.Analysis.Type': 'Downstream analysis',
    'Monocle.Type.In.Genes': 'Type in genes',
    'Monocle.Target.Gene.Expression.In.Pseudotime': 'Target gene expression in pseudotime',
    'Monocle.Trajectory.State.Expression': 'Trajectory state expression',
    'Monocle.Branch.Split': 'Branch split', 
    'Monocle.Downstream.Analysis.Type.Tips': '*Click to switch the analysis to submit and view the corresponding list of submitted tasks.',
    'Monocle.Branch.Split.Tips': 'The branch points can be viewed in the Facet - Facet state diagram above.',
   
    // 3
    'Monocle.Root.Selection': 'Root selection',
    'Monocle.Nodelist': 'Nodelist',
    'Monocle.Fina.Variable.Genes': 'Find variable genes',
    'Monocle.Variable.Nodelist': 'Nodelist: ',
    'Monocle.Variable.Partitions': 'Partitions: ',
    'Monocle.Variable.Resolution': 'Resolution: ',
    'Monocle.Variable.Q.Value.Threshold': 'Q value threshold: ',
    'Monocle.Gene.Module.Expression.Heatmap': 'Gene module expression heatmap',
    'Monocle.Variable.Gene.Table': 'Variable gene table',
    'Monocle.Variable.Gene.Table.Download': 'Download variable gene table files',
    'Monocle.Selected.Gene.Across.Pseudotime': 'Selected variable gene expression change across pseudotime',
    'Monocle.Gene.Expression.Changes.Across.Pseudotime': 'Gene expression changes across pseudotime',

    'Monocle.Variable.Nodelist.Tips': 'Node list: Choose the pseudotime results generated by specified root nodes to find variable genes. ',
    "Monocle.Variable.Partitions.Tips": 'Partitions determine cells used to find variable genes. Default = all partitions.',
    'Monocle.Variable.Resolution.Tips': 'Resolution for grouping variable genes into modules. Higher resolution results in more modules generated. Default = 0.001.',
    'Monocle.Variable.Q.Value.Tips': 'Q value for filtering variable genes. Defeault = 0.05.',

    'Monocle.Table.Id': 'Id',
    'Monocle.Table.Gene.Short.Name': 'Gene_short_name',
    "Monocle.Table.P.value": 'P_value',
    'Monocle.Table.Morans.Test.Statistic': 'Morans_test_statistic',
    'Monocle.Table.Morans.Imorans.I': 'Morans_Imorans_I',
    'Monocle.Table.Status': 'Status',
    'Monocle.Table.Module': 'Module',
    "Monocle.Table.Supermodule": 'Supermodule',

    "Monocle.Task.Submit": 'Submitted',
    "Monocle.Task.Error.Submit": 'Job unable to submit',
};