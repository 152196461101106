const datasetModule = {
    namespaced: true,
    state: {
        cellTypesOptions: [],
        allColorByOtions: [],
        colorByModelValue: [],
        colorByModelValueOfCellTypes: [],
        colorByLastModelValueOfCellTypes: '',
        allColorByOtionsWithCategories: {}, // 前端做过筛选
        currentDimension:2, // 当前数据集维度
        currentDimensionName: 'umap', // 当前数据集维度名称
        isHasFilterLayer: false,
        allOptionsWithCategories: [],  //没有做过筛选
    },
    mutations: {
        changeColorByOptionsM(state, data) {
            state.allColorByOtions = data;
            const res = data.find(item => item.value === 'cell types');
            if (res) {
                state.cellTypesOptions = res.children;
            }
        },
        changeColorByModelValueM(state, data) {
            state.colorByModelValue = data;
            if (data && data[0] === 'cell types') {
                state.colorByModelValueOfCellTypes = data;
                state.colorByLastModelValueOfCellTypes = data[1];
            }
        },
        changeAllColorByOptionsWithCategoriesM(state, data) {
            state.allColorByOtionsWithCategories = data;
        },
        changeCurrentDimensionInfoM(state, data) {
            state.currentDimension = data.currentDimension;
            if(data.currentDimensionName) {
                state.currentDimensionName = data.currentDimensionName;
            }
        },
        changeIsHasFilterLayerM(state, data) {
            state.isHasFilterLayer = data;
        },
        changeAllOptionsWithCategoriesM(state, data) {
            state.allOptionsWithCategories = data;
        },

    },
    actions: {
        changeColorByOptionsA({ commit }, data) {
            commit('changeColorByOptionsM', data);
        },

        changeColorByModelValueA({ commit }, data) {
            commit('changeColorByModelValueM', data);
        },
        changeAllColorByOptionsWithCategoriesA({ commit }, data) {
            commit('changeAllColorByOptionsWithCategoriesM', data);
        },
        changeCurrentDimensionInfoA({ commit }, data) { // 改变当前维度信息
            commit('changeCurrentDimensionInfoM', data);
        },
        changeIsHasFilterLayerA({ commit }, data) { // 处理layer信息
            commit('changeIsHasFilterLayerM', data);
        },
        changeAllOptionsWithCategoriesA({commit}, data) {
            commit('changeAllOptionsWithCategoriesM', data);
        },
    },
};

export default datasetModule;