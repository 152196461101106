export const menuListCN = {
    'Menu.List.My Analyzes': '我的分析',
    'Menu.List.My.Tools': '工具',
    'Menu.List.Import.Data': '上传数据',

    'Menu.List.Integrate.Data': '启动分析',
    'Menu.List.Integrate.Local.Data': '整合本地数据',
    'Menu.List.Integrate.SynEcoSys.Data': '整合公共数据(SynEcoSys)',
    'Menu.List.Integrate.Local.SynEcoSys.Data': '整合本地和公共数据(SynEcoSys)',
    'Menu.List.Integrate.Local.SynEcoSys.Data.Br': '整合本地<br>和公共数据(SynEcoSys)',
    'Menu.List.Resource': '资料',
    'Menu.List.TutorialVideos': '教学视频',
    'Menu.List.Documentation': '说明文档',
    'Menu.List.GuideBook': '操作手册',
    'Menu.List.FAQ': 'FAQ',
    'Menu.List.Contact': '联系方式',

    'Menu.List.My.Account': '我的账户',
    'Menu.List.Requested.Downloads': '下载申请',
    'Menu.List.Notifications': '通知',
    'Menu.List.Account.Info': '账户信息',

    'Menu.List.Logout': '退出登录',
};

export const menuListUS = {
    'Menu.List.My.Tools': 'Tools',
    'Menu.List.My Analyzes': 'My Analyses',
    'Menu.List.Import.Data': 'Import Data',

    'Menu.List.Integrate.Data': 'Initiate Analysis',
    'Menu.List.Integrate.Local.Data': 'Integrate local data',
    'Menu.List.Integrate.SynEcoSys.Data': 'Integrate SynEcoSys data',
    'Menu.List.Integrate.Local.SynEcoSys.Data': 'Integrate local and SynEcoSys data',
    'Menu.List.Integrate.Local.SynEcoSys.Data.Br': 'Integrate local <br> and SynEcoSys data',

    'Menu.List.Resource': 'Resource',
    'Menu.List.TutorialVideos': 'Tutorial Videos',
    'Menu.List.Documentation': 'Documentation',
    'Menu.List.GuideBook': 'GuideBook',
    'Menu.List.FAQ': 'FAQ',
    'Menu.List.Contact': 'Contact',

    'Menu.List.My.Account': 'My account',
    'Menu.List.Requested.Downloads': 'Requested downloads',
    'Menu.List.Notifications': 'Notifications',
    'Menu.List.Account.Info': 'Account info',

    'Menu.List.Logout': 'Logout',
};