import { faqCN, faqUS} from "@/locales/lang_modules/resources/faq";
import { contactUsCN, contactUsUS} from "@/locales/lang_modules/resources/contactUs";

export const mainResourcesCN = {
    ...faqCN,
    ...contactUsCN,
};
export const mainResourcesUS = {
    ...faqUS,
    ...contactUsUS,
};