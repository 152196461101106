export const cellQualityUS = {

    'Cell.Quality.Title': 'Explore Cell Quality',
    'Cell.Quality.Item': 'Expression QC',
    'Cell.Quality.Item1': 'Contamination',
    'Cell.Quality.Item2': 'Doublets',
    'Cell.Quality.Item3': 'Cell cycle effects',

    'Expression.QC.Sample.Group': 'By sample',
    'Expression.QC.Sample.Title1': 'Per-sample mitochondrial content distribution',
    // 'Expression.QC.Sample.Plot.Title1': 'percent_mt',
    'Expression.QC.Sample.Title2': 'Per-sample gene count distribution',
    // 'Expression.QC.Sample.Plot.Title2': 'nFeature_RNA',
    'Expression.QC.Sample.Title3': 'Per-sample UMI count distribution',
    // 'Expression.QC.Sample.Plot.Title3': 'nCount_RNA',

    'Expression.QC.Cluster.Group': 'By cluster',
    'Expression.QC.Cluster.Title1': 'Per cluster mitochondrial content distribution',
    'Expression.QC.Cluster.Title2': 'Per cluster gene count distribution',
    'Expression.QC.Cluster.Title3': 'Per cluster UMI count distribution',

    'Contaminations.Select.Label': 'Detected contamination per sample',
    'Contaminations.Plot.Title1': 'Distribution of contamination levels',

    'Contaminations.Download.Name': 'Download per sample cell contamination score',

    'Contaminations.Title': 'Recommendation per sample',
    // 'Contaminations.Table.Line': 'Sample ID',
    'Contaminations.Table.Line1': 'DecontX estimated contamination proportion',
    'Contaminations.Table.Line1.tips': 'If the estimated contamination proportion value is greater than 0.25, the sample is generally considered to be contaminated.',

    'Doublets.Select.Lable': 'Detected doublets per sample',
    // 'Doublets.Plot.Title1': 'Observed transcriptomes',
    // 'Doublets.Plot.X.Title': 'Doublet score',
    // 'Doublets.Plot.Y.Title': 'Prob.density',
    // 'Doublets.Plot.Title2': 'Simulated doublets',
    'Doublets.Download.Name': 'Download per sample doublet predictions',
    'Doublets.Title': 'Doublet ratio per sample',
    'Doublets.Table.Line': 'Doublet ratio',

    'Cell.Cycle.Effects.Titele': 'Cells in phases of the cell cycle',
};

export const cellQualityCN = {
    'Cell.Quality.Title': '细胞质控',
    'Cell.Quality.Item': '表达质控',
    'Cell.Quality.Item1': '细胞污染',
    'Cell.Quality.Item2': '双胞预测',
    'Cell.Quality.Item3': '细胞周期影响',

    'Expression.QC.Sample.Group': '样本',
    'Expression.QC.Sample.Title1': '样本线粒体含量分布',
    // 'Expression.QC.Sample.Plot.Title1': 'percent_mt',
    'Expression.QC.Sample.Title2': '样本基因数量分布',
    // 'Expression.QC.Sample.Plot.Title2': 'nFeature_RNA',
    'Expression.QC.Sample.Title3': '样本UMI数量分布',
    // 'Expression.QC.Sample.Plot.Title3': 'nCount_RNA',

    'Expression.QC.Cluster.Group': 'Cluster',
    'Expression.QC.Cluster.Title1': 'Cluster线粒体含量分布',
    'Expression.QC.Cluster.Title2': 'Cluster基因数量分布',
    'Expression.QC.Cluster.Title3': 'Cluster UMI数量分布',

    'Contaminations.Select.Label': '样本污染检测',
    'Contaminations.Plot.Title1': '污染水平分布',
    'Contaminations.Download.Name': '下载细胞污染打分文件',

    'Contaminations.Title': '样本处理建议',
    // 'Contaminations.Table.Line': 'Sample ID',
    'Contaminations.Table.Line1': 'DecontX预估污染比例',
    'Contaminations.Table.Line1.tips': '如果预估污染比例值大于 0.25，则考虑认为该样本受到了污染',

    'Doublets.Select.Lable': '样本双胞检测',
    // 'Doublets.Plot.Title1': 'Observed transcriptomes',
    // 'Doublets.Plot.X.Title': 'Doublet score',
    // 'Doublets.Plot.Y.Title': 'Prob.density',
    // 'Doublets.Plot.Title2': 'Simulated doublets',
    'Doublets.Download.Name': '下载样本双胞预测文件',
    'Doublets.Title': ' 样本双胞占比',
    'Doublets.Table.Line': '双胞占比',

    'Cell.Cycle.Effects.Titele': '细胞周期中的细胞',
};