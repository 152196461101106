import { URL_PREFIX } from "@/const/const";

export default class notificationsServer {

    // 获取通知未读数量
    static API_PUT_USER_NOTIFICATIONS_UNREAD = URL_PREFIX + "/user/notifications/unread";

    // 通知列表
    static DATABASE_API_GET_USER_NOTIFICATIONS = URL_PREFIX + "/user/notifications";

    // 标记已读
    static DATABASE_API_PUT_USER_NOTIFICATIONS_ID = URL_PREFIX + "/user/notifications/{id}";

    // 删除通知
    static DATABASE_API_DETELE_USER_NOTIFICATIONS = URL_PREFIX + "/user/notifications";

}