import { Local, Session } from "@/utils/storage";
import { findNodePath, findNode } from "@/utils/getTreeNodes.js";
import { handleUserMenuAuth } from '@/utils/auth';
import { getUserUnReadNotifications } from "@/api/myAccount/notifications";
export default ({
  name: 'SingleronPcMenus',
  components: {
  },
  data() {
    return {
      userName: 'My analyses',
      menusList: [],
      unReadNotifications: 0,
    };
  },
  props: {
  },
  watch: {
    $route() {
      // if(from.path === '/importdata') {
      this.refreshRoute();
      // }
    },
  },
  computed: {
    activePath() {
      // console.log('111111222222');
      return this.$store.state.routesList.activePath;
    },
  },
  mounted() {
    this.initData();
    // this.authorityJudgment();
  },
  methods: {
    // 处理菜单权限
    handleAuth() {
      const userInfo = Local.get('user_info');
      if (!userInfo) { return; }
      const menu = userInfo.privileges;
      handleUserMenuAuth(menu);
      this.userName = Local.get('username');
      this.menusList = this.$store.state.routesList.routesList;
      // this.menusList = JSON.parse(JSON.stringify(this.menusList));
      this.menusList.find(item => item.id === 'Celelens:UserName').name = this.userName;
      this.menusList.find(item => item.id === 'Celelens:UserName').label = this.userName;
    },

    initData() {
      this.handleAuth();
      this.loadNotifications();
      this.refreshRoute();
    },

    refreshRoute() {
      const activePath = this.$store.state.routesList.activePath;
      if (!activePath) {
        return;
      }
      this.handleRefreshRouteSwitch(activePath);
      // this.menusList.push({
      //   "name": "App 1",
      //   "id": "CeleLens:MyAnalyzes1",
      //   "children": [],
      //   "routePath": "/app1/AllTools",
      //   "active": false,
      //   "disabled": false,
      //   "hidden": false,
      //   "parentId": 0,
      //   "selfId": 51,
      //   "label": "Menu.List.My Analyzes1"
      // })
    },

    handleRefreshRouteSwitch(activePath) {
      this.findFatherNode('routePath', activePath);
    },

    handleCommand(val) {
      if (val === 'Logout') {
        this.$emit('logout');
        return;
      }
      this.handleRoute('id', val);
      this.findFatherNode('id', val);
    },

    // 处理路由跳转
    handleRoute(key, val) {
      const targetNode = findNode(key, this.menusList, val);
      if (targetNode && targetNode.disabled) {
        return;
      }
      if (targetNode && targetNode.routePath) {
        this.$router.push({
          path: targetNode.routePath,
          query: targetNode.query? targetNode.query : {}});
          this.$emit('sendRoute');
      }
    },

    // 查询当前节点的顶级父节点
    findFatherNode(key, val) {
      let formatVal = val;
      let formatKey = key;
      // console.log('1111111111', key);
      // console.log('2222222', val);
      if (val === '/') {
        formatVal = 'Homepage';
        formatKey = 'id';
      }
      const nodeArray = findNodePath(formatKey, this.menusList, formatVal);

      if (!nodeArray.length) {
        return;
      }
      const firstNode = nodeArray[0];
      this.menusList.forEach(item => item.active = false);
      this.menusList.find(item => item.id === firstNode.id).active = true;
    },
    async loadNotifications() {
      try {
        const serverData = await getUserUnReadNotifications();
        if (!serverData.data) {
          return;
        }
        const data = serverData.data;
        if (data > 99) {
          this.unReadNotifications = '99+';
        } else if (data > 0) {
          this.unReadNotifications = data;
        } else {
          this.unReadNotifications = 0;
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
});