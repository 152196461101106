import ServerConstants from './server.js';
import server from '@/server/request.js';

/**
 * 联系我们
 * **/
export function contact_us(params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.postJSON(ServerConstants.USER_DATABASE_API_POST_USER_CONTACT_US, params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}

/**
 * faq - 获取所有问答
 * **/
export function getFaqAllItem(params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.getJSON(ServerConstants.DATABASE_API_GET_ALL_FAQITEM, params, function (response) {
            resolve(response.data);
            server.call(success, response);
        }, function (error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}


/**
 * faq -- 获取内容列表
 * **/
export function getFaqListByType(params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.getJSON(ServerConstants.CONTENT_API_CONTENT_GET_LIST, params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}

/**
 * 更新日志
 * **/
export function getUpdateLogData(params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.getJSON(ServerConstants.CONTENT_API_UPDATELOG, params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}
