const downloadImage = {
    namespaced: true,
    state: {
        changeColor: {}, // 修改颜色命令
        plotly: {}, // plotly.js绘图相关
        d3: {}, // d3.js 绘图相关
        canvas: {}, // canvas绘图相关
    },
    mutations: {
        changeColorByUser1(state, data) {
            state.changeColor = data;
        },
        changePlotlyByUser(state, data) {
            state.plotly = data;
        },
        changeD3ByUser(state, data) {
            state.d3 = data;
        },
        changeCanvasByUser(state, data) {
            state.canvas = data;
        },
    },
    actions: {
        changeColorByUser1Function({commit}, data) {
            commit('changeColorByUser1', data);
        },
        changePlotlyByUserFunction({commit}, data) {
            commit('changePlotlyByUser', data);
        },
        changeD3ByUserFunction({commit}, data) {
            commit('changeD3ByUser', data);
        },
        changeCanvasByUserFunction({commit}, data) {
            commit('changeCanvasByUser', data);
        },
    },
};
export default downloadImage;