import {  initGlobalState } from "qiankun";
import { isCNSite } from "@/utils";
import server from "@/server/request";
const state = {
  message: 'Hello, qiankun!',
  lang: isCNSite() ?  'zh-CN' : 'en-US',
  state: 0,
}; 
const actions = initGlobalState(state);
actions.onGlobalStateChange((after, prev) => {
  // state: 变更后的状态; prev 变更前的状态
  console.log('father:', after, prev);
  if(after.state === 401) {
    server.authTip();
  } if (after.state >= 500) {
    server.serverCloseTip();
  }
});
export {  actions };