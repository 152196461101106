import DataFrame from 'dataframe-js-sort';

/**
 *
 * @param {*} row 行内容对象数组 [
        {c1: 1, c2: 6}, // <------- A row
        {c4: 1, c3: 2}
    ]
 * @param {*} tableTitleArray 表头列表
 */
export function dataFromCollection(row, tableTitleArray) {
    const df = new DataFrame(row, tableTitleArray);
    // df.show();
    return df;
}
/**
 * 返回文件字符串
 * @param {*} header 是否需要文件头
 * @param {*} df  dataframe数据类型
 * @param {*} type 文件类型
 * @returns
 */
export function returnStringByType(df, type, header = true) {
    if (type.toUpperCase() === 'CSV') {
        const res = df.toCSV(header);
        return res;
    }
}
/**
 * 返回文件字符串
 * @param {*} url url
 * @returns
 */
export async function readDataFromUrl(url) {
    const df = await DataFrame.fromCSV(url);
    return df;
}

/**
 * 返回table表格数据
 * @param {*} df  dataframe数据类型
 * @returns
 */
export async function returnToTableData(df) {
    return df.toCollection();
}

/**
 * 返回修改名称后的数据
 * @param {*} df  dataframe数据类型
 * @param oldName 初始名称
 * @param newName 新名称
 * @param ifRenameAll 是否全命名
 * @returns
 */
export function returnNewName(df, oldName, newName, ifRenameAll) {
    if ( ifRenameAll ) {
        return df.renameAll(newName);
    } 
        return df.rename(oldName, newName);
    
}

/**
 * 过滤
 * @param {*} df  dataframe数据类型
 * @param params 过滤条件
 * @returns
 */
export function returnFilterData(df, params) {
    return df.filter(params);
}

/**
 * 排序
 * @param {*} df  dataframe数据类型
 * @param params 过滤条件
 * @param isDesc 是否降序
 * @returns
 */
export function returnSortByData(df, params, isDesc) {
    // console.log('=============.', params, isDesc)
    return df.sortBy(params, isDesc);
}

/**
 * 多列不同排序方式
 * @param {*} df  dataframe数据类型
 * @param columns 过滤条件 ---- 目前过滤条件为空时，需要前置处理， 判断空时，自己处理，不使用此方法
 * @param reverseArray 排序方式
 * @returns
 */
export function returnSortByMultiReverseData(df, columns, reverseArray) {
    return df.sortByMultiReverse(columns, reverseArray);
}
/**
 * 返回排序后的表格
 * @returns
 * @param tableData
 * @param tableHeader 表头
 * @param rules object such as {'name': true, 'age': false} true表示降序， false表示升序
 */
export function returnTableSortByMultiOption(tableData, tableHeader, rules) {
    // 将表格转成dataframe格式
    const df = dataFromCollection(tableData, tableHeader);
    // 获取排序名称和排序方式
    const columnNames = Object.keys(rules);
    const sortReverse = Object.values(rules);
    // 返回排序后的表格
    if (columnNames.length) {
        const sortByData = returnSortByMultiReverseData(df, columnNames, sortReverse);
        return returnToTableData(sortByData);
    } 
        return JSON.parse(JSON.stringify(tableData));
    
}

/**
 * 字典
 * @param {*} df  dataframe数据类型
 * @returns
 */
export function returnDict(df) {
    return df.toDict();
}

/**
 * 转置
 * @param {*} df  dataframe数据类型
 * @returns
 */
// df.transpose(), df.transpose().stat.sum('0')
export function returnTransposeDf(df) {
    return df.transpose();
}

/**
 * 求和
 * @param {*} df  dataframe数据类型
 * @param columnName 列名
 * @returns
 */

export function returnSum(df, columnName) {
    return df.stat.sum(columnName);
}


/**
 * 替换值
 * @param {*} df  dataframe数据类型
 * @param oldValue 旧值
 * @param newValue 新值
 * @param columnName 列名
 * @returns
 */

export function replaceColumns(df, oldValue, newValue, columnName) {
    return df.replace(oldValue, newValue, columnName);
}


/**
 * 修改或新增
 * @param {*} df  dataframe数据类型
 * @param key key
 * @param value value
 * @returns
 */
export function addOrResetValue(df, key, value) {
    const newDf = df.map(row => row.set(key, value));
    return newDf;
}


/**
 * 分组
 * @param {*} df  dataframe数据类型
 * @param groupName 分组名
 * @returns
 */
export function returnGroupDF(df, groupName) {
    return df.groupBy(groupName);
}



