import {colorPanel} from "@/const/colorPicker";
const changeUmap = {
    namespaced: true,
    // 声明状态
    state: {
        colorByMethodToChangeColors: {
            origin: colorPanel['Default'],
        },
        umapWidthAndHeight: {
            width: 860,
            height: 710,
        },
        pointSize: 5,
        pointOpacity: 2,
        currentActiveSubset: '', // example: 'annot_full'
        legendFontSize: 12,
    },
    // 更新状态
    mutations: {
        changeColorByUser(state, data) {
            state.colorByMethodToChangeColors = JSON.parse(JSON.stringify(data));
        },
        changeUmapSizeByUser(state, data) {
            state.umapWidthAndHeight = data;
        },
        changePointSizeM(state, data) {
            state.pointSize = data;
        },
        changePointOpacityM(state, data) {
            state.pointOpacity = data;
        },
        changeActiveSubsetM(state, data) {
            state.currentActiveSubset = data;
        },
        changeLegendFontSize(state, data) {
            state.legendFontSize = data;
        },
    },
    // 给actions注册一个事件处理函数，当这个函数触发时，将状态提交到mutations中处理
    actions: {
        changeColorByUserFunction({commit}, data) {
            commit('changeColorByUser', data); // 提交到mutations处理
        },
        changeSizeByUserFunction({commit}, data) {
            commit('changeUmapSizeByUser', data); // 提交到mutations处理
        },
        changePointSizeA({commit}, data) {
            commit('changePointSizeM', data); // 提交到mutations处理
        },
        changePointOpacityA({commit}, data) {
            commit('changePointOpacityM', data); // 提交到mutations处理
        },
        changeActiveSubsetA({commit}, data) {
            commit('changeActiveSubsetM', data); 
        },
        changeLegendFontSizeA({commit}, data) {
            commit('changeLegendFontSize', data);
        },
    },
};
export default changeUmap;