export const requestedDownloadsCN = {
    'Requested.Download.Active.Downloads': '已激活下载',
    'Requested.Download.Requested.Downloads': '请求下载',
    'Requested.Download.Expired.Downloads': '过期下载',
};

export const requestedDownloadsUS = {
    'Requested.Download.Active.Downloads': 'Active downloads',
    'Requested.Download.Requested.Downloads': 'Requested downloads',
    'Requested.Download.Expired.Downloads': 'Expired downloads',
};