<template>
  <el-popover
      :placement="position"
      title=""
      :width="width"
      trigger="hover">
    <span v-html="$t(popoverTip)" :class="className" class="popover-txt"></span>
    <span class="aHref" @click="toDocumentation">{{aHref}}</span>
    <slot name="example"></slot>
    <span slot="reference">
      <span v-if="defaultIcon">
        <i class="el-icon-question"></i>
        <img src="@/assets/myanalyzes/warn/warn.png" v-show="iconType === 'warning'" alt="">
      </span>
      <strong v-else class="el-icon-question2">?</strong>
    </span>
  </el-popover>
</template>

<script>
export default {
  name: "ExplainInformation",
  data() {
    return {

    };
  },
  props: {
    width: {
      type: Number,
      default: 300,
    },
    popoverTip: [String],
    aHref: {
      type: String,
      default: '',
    },
    hrefUrl: {
      type: String,
      default: '/documentation',
    },
    iconType: {
      type: String,
      default: 'tooltip',
    },
    position: {
      type: String,
      default: 'bottom',
    },
    defaultIcon: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
      default: '',
    },
  },
  methods: {
    toDocumentation() {
      this.$router.push({
        path: '/documentation',
      });
    },
  },
};
</script>

<style scoped lang="scss">
  img {
    width: 18px;
    cursor: pointer;
  }
  .aHref {
    text-decoration: underline;
    color: $main-color;
    cursor: pointer;
  }
  .el-icon-question {
    color: $main-color;
    font-size: 20px;
    cursor: pointer;
  }
  .popover-txt {
    color: #606266;
    line-height: 1.4;
    text-align: justify;
    font-size: 14px;
    &.tips-wrapper {
      color: #000000;
    }
  }
  .el-icon-question2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border: 1px solid $main-color;
    color: $main-color;
    font-weight: 800;
    border-radius: 100%;
    cursor: pointer;
  }
</style>