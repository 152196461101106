import menusPcComp from './components/pcMenus/index.vue';
import mobileMenusComp from './components/mobileMenus/index.vue';
// import { resetRouter } from "@/router";
import { mapActions } from "vuex";
import languageComp from '@/components/SelectLang/index.vue';
import logAndFeedBackComp from "@/components/logAndFeedBackComp";

export default ({
  name: 'SingleronNavMenus',
  components: {
    'menus-pc-comp': menusPcComp,
    'mobile-menus-comp': mobileMenusComp,
    'language-comp': languageComp,
    logAndFeedBackComp,
	},
  data() {
    return {
    };
  },
  mounted(){
  },
  methods:{
    getRoute() {
      this.$refs.updateLogRef.closeDrawer();
    },
    goHome() {
      this.$router.push("/");
    },
    //清空缓存中的值
    ...mapActions(['user/logout']),
    logout() {
      this.$msgbox({
        closeOnClickModal: false,
        closeOnPressEscape: false,
        title: this.$t("message.user.logOutTitle"),
        message: this.$t("message.user.logOutMessage"),
        showCancelButton: true,
        confirmButtonText: this.$t("message.user.logOutConfirm"),
        cancelButtonText: this.$t("message.user.logOutCancel"),
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = this.$t("message.user.logOutExit");
            setTimeout(() => {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 300);
            }, 700);
          } else {
            done();
          }
        },
      })
        .then(async () => {
          // this.$store.dispatch("user/logout"); // 清空 vuex 路由列表缓存
          // resetRouter(); // 删除/重置路由
          await this['user/logout']();
          this.$router.push({
                path: '/login',
                query: {
                  redirect: this.$route.fullPath,
                },
          });
          // location.reload();
        })
        .catch(() => {});
    },
  },
});