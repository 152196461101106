export const contactUsCN = {
    'Contact.Us.Title.Contact.Us': '联系我们',
    'Contact.Us.Email': '邮箱',
    'Contact.Us.Telephone': '电话',
    'Contact.Us.Need.Help': '需要帮助?',
    'Contact.Us.Your.Question': '您的问题',

    'Contact.Us.Country.China': 'China',
    'Contact.Us.China.Nanjing': 'Building 06, Accelerator Phase II Yaogu Avenue 11',
    'Contact.Us.China.Suzhou': 'Buliding 01, Biobay Phase III Xinze Road 1',

    'Contact.Us.Country.Singapore': 'Singapore',
    'Contact.Us.Singapore': '61 Science Park Rd, #02-08, Singapore 117525',

    'Contact.Us.Country.Germany': 'Germany',
    'Contact.Us.Germany': 'Gottfried-Hagen-Straße 60, 51105 Köln, Germany',

    'Contact.Us.Country.USA': 'USA',

    'Contact.Us.USA': '1 Bradley Road, Suite 401 Woodbridge, CT 06525',
    'Contact.Us.USA2': '333 Jackson plaza, Ann Arbor, MI 48103',
};
export const contactUsUS = {
    'Contact.Us.Title.Contact.Us': 'Contact us',
    'Contact.Us.Email': 'Email',
    'Contact.Us.Telephone': 'Phone',
    'Contact.Us.Need.Help': 'Need help?',
    'Contact.Us.Your.Question': 'Your question',


    'Contact.Us.Country.China': 'China',
    'Contact.Us.China.Nanjing': 'Building 06, Accelerator Phase II Yaogu Avenue 11',
    'Contact.Us.China.Suzhou': 'Buliding 01, Biobay Phase III Xinze Road 1',

    'Contact.Us.Country.Singapore': 'Singapore',
    'Contact.Us.Singapore': '61 Science Park Rd, #02-08, Singapore 117525',

    'Contact.Us.Country.Germany': 'Germany',
    'Contact.Us.Germany': 'Gottfried-Hagen-Straße 60, 51105 Köln, Germany',

    'Contact.Us.Country.USA': 'USA',
    'Contact.Us.USA': '1 Bradley Road, Suite 401 Woodbridge, CT 06525',
    'Contact.Us.USA2': '333 Jackson plaza, Ann Arbor, MI 48103',
};
