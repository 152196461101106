// const sySite = process.env.NODE_ENV === 'development' ? 'http://bi-dev.internal.singleronbio.com/bi.html#/' : 'https://www.synecosys.cn/bi.html#/';
// const celSite = process.env.NODE_ENV === 'development' ? 'http://bi-dev.internal.singleronbio.com/#/celevizbrowser' : 'https://www.synecosys.cn/#/celevizbrowser';
// console.log('production', process.env.VUE_APP_NODE_TEST);
let sySite = 'http://bi-dev.internal.singleronbio.com/bi.html#/';
let celSite = 'http://bi-dev.internal.singleronbio.com/#/celevizbrowser';
if(process.env.VUE_APP_NODE_TEST === 'production') {
    sySite = 'https://www.synecosys.cn/bi.html#/';
    celSite = 'https://www.synecosys.cn/#/celevizbrowser';
} else if(process.env.VUE_APP_NODE_TEST === 'awsproduction') {
    sySite = 'https://www.synecosys.com/bi.html#/';
    celSite = 'https://www.synecosys.com/#/celevizbrowser';
} else if(process.env.VUE_APP_NODE_TEST === 'stage') {
    sySite = 'http://bi-alpha.internal.singleronbio.com/';
    celSite = 'http://bi-alpha.internal.singleronbio.com/#/celevizbrowser';
}
export const homeMenuList = [{
    active: true,
    id: 'CeleLensCloudPlatform',
    name: 'CeleLens cloud Platform',
    routePath: '',
    children: [{
        active: false,
        id: 'SynEcoSysDatabase',
        name: 'SynEcoSys Database',
        routePath: sySite,
    },{
        active: false,
        id: 'CeleVizBrowser',
        name: 'CeleViz Browser',
        routePath: celSite,
    },{
        active: false,
        id: 'CeleLensCloudPlatform',
        name: 'CeleLens cloud Platform',
        routePath: '',
    }],
},{
    active: false,
    id: 'aboutUs',
    name: 'About us',
    routePath: 'https://singleron.bio/about/list-5.html',
    flag: true,
}];
export const windowRouterPath = {
    SynEcoSysDatabase: sySite,
    CeleVizBrowser: celSite,
};


export const mainMenuListCNUS = {
    'My Analyzes': 'Menu.List.My Analyzes',
    Tools: 'Menu.List.My.Tools',
    'Import data': 'Menu.List.Import.Data',

    'Intergrate data': 'Menu.List.Integrate.Data',
    "Integrate local data": 'Menu.List.Integrate.Local.Data',
    "Integrate SynEcoSys data": 'Menu.List.Integrate.SynEcoSys.Data',
    "Integrate local and Integrate SynEcoSys data": 'Menu.List.Integrate.Local.SynEcoSys.Data',

    Resource: 'Menu.List.Resource',
    TutorialVideos: 'Menu.List.TutorialVideos',
    Documentation: 'Menu.List.Documentation',
    GuideBook: 'Menu.List.GuideBook',
    FAQ: 'Menu.List.FAQ',
    Contact: 'Menu.List.Contact',

    "My account": 'Menu.List.My.Account',
    'Requested downloads': 'Menu.List.Requested.Downloads',
    Notifications: 'Menu.List.Notifications',
    'Account info': 'Menu.List.Account.Info',
};