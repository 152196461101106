// 获取group的名称
import {removeColorMethods, specialString} from "@/const/const";
import { getFilterOptions } from "@/api/userDataset/viewData";
import {ALL_CELL_TYPE_FORMAT_LABEL} from "@/const/umap";

export function achieveSelectedName(toFilterCellType) {
    const data = {};
    let sampleName = '';
    let sampleName1 = '';
    Object.keys(toFilterCellType).map((item) => {
        let name = '';
        if (item !== 'time' && item !== 'sign') {
            const value = toFilterCellType[item];
            data[item] = [value];
            // name = item + ': ' + JSON.stringify(value);
            name = item + ': ' + value;
            sampleName += name + ', ';
            sampleName1 += name + '; ';
            // console.log('mcdkwkwd', data, value, sampleName1, name)
        }
    });
    return [data, sampleName, sampleName1];
}


/**
 * 给散点图的点排序
 * sampleGroupParams 复选框选中的值
 * filterSampleData 过滤条件
 * checkOptions 复选框model
 */
export async function handleDisabledOptions(sampleGroupParams, filterSampleData, checkOptions) {
    // console.log('[sampleGroupParams, checkOptions]', [sampleGroupParams, JSON.parse(JSON.stringify(checkOptions))], filterSampleData);
    Object.keys(sampleGroupParams).forEach(group => {
        // console.log('group', group);
        sampleGroupParams[group].forEach(cate => {
            if (!filterSampleData[group].includes(cate)) {
                // if (!Object.keys(filterSampleData[group]).includes(cate)) {
                // 取消复选框选中状态和筛选参数中的值
                const deleteCate = cate + group;
                const index = checkOptions.indexOf(deleteCate);
                checkOptions.splice(index, 1);
                const index2 = sampleGroupParams[group].indexOf(cate);
                sampleGroupParams[group].splice(index2, 1);
            }
        });
        if (!sampleGroupParams[group].length) {
            delete sampleGroupParams[group];
        }
    });
    return [sampleGroupParams, checkOptions];
}
/**
 * 给散点图的点排序
 * groupParams 复选框选中的值
 * apiParams 接口参数
 * historySampleGroup 历史数据
 * filterSampleData 过滤条件
 * sampleData 初始状态值
 * sampleGroupParams
 * checkOptions
 * stringId 数据集id
 * apiType 数据集信息（公共数据集or用户数据集）
 */
export async function handleAddOrRemoveOptions(groupParams, apiParams, historySampleGroup, filterSampleData, sampleData, sampleGroupParams, checkOptions, stringId, apiType, selectedDatsetsUuids) {
    // sample 为空时，恢复初始状态
    let disabled;
    if (!Object.keys(groupParams).length) {
        historySampleGroup = [sampleData];
        filterSampleData = JSON.parse(JSON.stringify(sampleData));
        return [filterSampleData, historySampleGroup, sampleGroupParams, checkOptions];
    }
    // console.log('groupParams', groupParams, apiParams);
    for (const item of Object.keys(groupParams)) {
        // console.log('historySampleGroup', JSON.parse(JSON.stringify(historySampleGroup)), apiParams);
        apiParams[item] = groupParams[item];
        const serverdata =  await getFilterOptions(stringId, apiParams);
        const data = serverdata.data;
        const allData = Object.assign({}, data['cell_type'], data['sample_group']);
        const filterSampleDataNew = {};
        Object.keys(allData).forEach(ele => {
            filterSampleDataNew[ele] = allData[ele]['categories'];
        });
        // console.log('123333333', filterSampleData);
        delete apiParams['time'];
        delete apiParams['sign'];
        // console.log('aaaaaaaaaaa', apiParams);
        Object.keys(apiParams).forEach(item1 => {
            // console.log('循环内部', item1, JSON.parse(JSON.stringify(historySampleGroup)));
            filterSampleDataNew[item1] = historySampleGroup[0][item1];
        });
        // disabled = await handleDisabledOptions(groupParams, filterSampleData);
        disabled = await handleDisabledOptions(sampleGroupParams, filterSampleDataNew, checkOptions);
        historySampleGroup = [filterSampleDataNew];
        filterSampleData = JSON.parse(JSON.stringify(filterSampleDataNew));
    }
    // console.log('apiParams', Object.keys(groupParams).length );
    if (Object.keys(groupParams).length === 1) {
        const key = Object.keys(groupParams)[0];
        filterSampleData[key] = sampleData[key];
        historySampleGroup = [filterSampleData];
    }
    // console.log('[filterSampleData, historySampleGroup, disabled[0], disabled[1]]', [filterSampleData, historySampleGroup]);
    return [filterSampleData, historySampleGroup, disabled[0], disabled[1]];
}

// 向上保留小数
export function toCeil(num, byte) {
    const n = Math.pow(10, byte);
    return Math.ceil(num * n) / n;
}


// 修改参数过长问题
export function transformObjToString(params) {
    let text = '';
    Object.keys(params).forEach(key => {
        const title = ALL_CELL_TYPE_FORMAT_LABEL[key] ? ALL_CELL_TYPE_FORMAT_LABEL[key] : key;
        text = text + title + ': ' + params[key].join(',') + ';';
    });
    return text;
}
export function handleLabelsTooLong(text, width = 30, finalWords = []) {
    // 尝试将字符分成若干等份，特殊字符包含  ----  逗号或空格或分号
    // 对空格特殊处理--将一个空格变成两个空格
    const spaceText = specialString['splitText'];
    // let splitText = text.split(' ').join("  ");
    let splitText = text;
    const speString = specialString['someSpecialString'];
    // 上面的这几张特殊字符，分割后加空格，后面用空格分割，拼接不加空格
    speString.forEach(splitStr => {
        splitText = splitText.split(splitStr).join(splitStr + spaceText);
    });
    // console.log('=============上述几种分割的字符串', splitText);

    let line = [];
    // const newWords = '';
    const words = splitText.split(spaceText);
    // console.log('=============上述几种分割的字符串', words, '');
    words.forEach(word => {
        if (word !== ' ') {
            line.push(word);
        }
        if (line.join('').length > width) {
            finalWords.push(line.join(''));
            line = [];
        }
    });
    // 将剩余的字符串拼接
    if (line.join('').length) {
        finalWords.push(line.join(''));
    }
    // finalWords = newWords;
    // console.log('======================打印查看long文本=====================', finalWords, ' '.length, finalWords.join('<br>'));
    return {newArr: finalWords, newWords: finalWords.join('<br>')};
}

export function sumArr(arr) {
    return arr.reduce(function(prev, curr, idx, arr) {
        return prev + curr;
    });
}

export function achieveSelectedGroupName(obj) {
    // console.log('00000000000obj', obj)
    const excludes = [
        "annot_full",
        "annot_sub",
        "annot_sub2",
        "annot_auto",
        "annot_article",
        "seurat_clusters",
        "cluster",
        "celltype",
    ];
    let group1 = [],
        group2 = [];
    let br = "";
    if (Object.keys(obj[0]).length > 2) {
        br = "<br>";
    } else {
        br = "";
    }
    Object.keys(obj[0]).forEach((item) => {
        if (!excludes.includes(item)) {
            let br1 = "",
                obj0 = "";
            let br2 = "",
                obj1 = "";
            obj[0][item].forEach((item1, ind) => {
                // console.log('==================>', item1)
                let vv = 3;
                item1.length > 30 ? vv = 1 : vv = 2;
                (ind + 1) % vv === 0 ? br1 = '<br>' :
                    ind === obj[0][item].length - 2 ? br1 = '<br>' :
                        ind === obj[0][item].length - 1 ? br1 = '' : br1 = '; ';
                obj0 += item1 + br1;
            });
            obj[1][item].length > 2 ? br2 = "<br>" : br2 = ", ";
            obj[1][item].forEach((item1, ind) => {
                let vv = 3;
                item1.length > 30 ? vv = 1 : vv = 2;
                (ind + 1) % vv === 0 ? br2 = '<br>' : ind === obj[1][item].length - 1 ? br2 = '' : br2 = '; ';
                obj1 += item1 + br2;
            });
            const name1 = item + ": " + obj0;
            const name2 = item + ": " + obj1;
            group1 += name1 + br;
            group2 += name2 + br;
        }
    });
    return [group1, group2];
}

export function toFilterObj(newVal) {
    const removeList = Object.freeze(removeColorMethods)['toRemoveList4'];
    const sampleData = {};
    Object.keys(newVal).forEach((item) => {
        if (!removeList.includes(item)) {
            const obj = {};
            const categories = newVal[item]['categories'];
            // if (categories.length < 2) return;
            categories.forEach(item1 => {
                obj[item1] = item1;
            });
            sampleData[item] = obj;
        }
    });
    return sampleData;
}

// 替换关键字高亮
export function replaceHighLightWord(searchVal, str) {
    const rHtml = new RegExp("<.*?>", "ig");
    let sText = str;
    const rStr = new RegExp(searchVal, "gi");
    let num = -1;
    const aHtml = sText.match(rHtml); //存放html元素的数组
    sText = sText.replace(rHtml, "{~}"); //替换html标签
    sText = sText.replace(
        rStr,
        `<font style="color:#0D65BD;font-weight:550;">${searchVal}</font>`
    ); //替换key
    sText = sText.replace(/{~}/g, function() {
        //恢复html标签
        num++;
        return aHtml[num];
    });
    return sText;
}

export function isCNSite() {
    return process.env.VUE_APP_NODE_TEST !== 'awsproduction';
}

export function cnSite() {
    return process.env.VUE_APP_NODE_TEST === 'production' && process.env.VUE_APP_BASE_API.includes('.cn/');
}



export function mergeObjects(obj1, obj2) {
    const result = { ...obj1 }; // 先复制obj1的内容到结果对象中
    for (const key in obj2) {
        if (Object.prototype.hasOwnProperty.call(obj2, key)) { // 确保key不是原型链上的属性
            if (Object.prototype.hasOwnProperty.call(result, key)) {
                // 如果键已存在，且你希望以特定方式合并值（例如，将它们放入数组）
                if (Array.isArray(result[key])) {
                    result[key] = [...new Set([...result[key], ...obj2[key]])];
                } else {
                    // 如果之前不是数组，现在将它们变成数组
                    result[key] = [...new Set([result[key], obj2[key]])];
                }
            } else {
                // 如果键不存在，直接赋值
                result[key] = obj2[key];
            }
        }
    }
    return result;
}
