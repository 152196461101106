import ServerConstants from './server.js';
import server from '@/server/request.js';
import { tpl_url } from "@/utils/common";

/**
 * 获取通知未读数量
 * @param {*} params
 * @param {*} success
 * @param {*} errorfun
 * @returns
 */
export function getUserUnReadNotifications(params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.getJSON(ServerConstants.API_PUT_USER_NOTIFICATIONS_UNREAD, params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}


/**
 * 获取通知列表
 * @param {*} params
 * @param {*} success
 * @param {*} errorfun
 * @returns
 */
export function getNotifications(params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.getJSON(ServerConstants.DATABASE_API_GET_USER_NOTIFICATIONS, params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}

/**
 * 标记通知已读
 * @param {*} params
 * @param {*} success
 * @param {*} errorfun
 * @returns
 */
export function readNotifications(stringId, params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.putFrom(tpl_url(ServerConstants.DATABASE_API_PUT_USER_NOTIFICATIONS_ID, stringId), params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}

/**
 * 删除通知
 * @param {*} ids
 * @param {*} success
 * @param {*} errorfun
 * @returns
 */
export function deleteNotifications(params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.deleteJSON(ServerConstants.DATABASE_API_DETELE_USER_NOTIFICATIONS, params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}
