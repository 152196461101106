export const removeRelated = {
    removeNotSampleGroup: ['annot_full', 'annot_sub', 'annot_sub2', 'Sample ID', 'annot_article', 'annot_auto', 'cluster'],
    publicRemoveGroup: [
        'Species', 'orig.ident', 'Days since diagnosed', 'If sorted',
        'n_', 'pct_', 'total_', 'RNA_snn_res',
        'annot_modified', 'phase',
    ],
    userRemoveGroup: [
        'Species','n_', 'pct_', 'total_',
    ],
    stageOptions: ['Stage3(Early or Late)', 'Stage2(I-IV)', 'Stage1(TNM)'],
    allCellTypes: ['cluster','annot_full', 'annot_sub', 'annot_sub2', 'annot_article', 'annot_auto'],
    annotLevel: ['annot_full', 'annot_sub', 'annot_sub2'],
};

export const renameGroup = {
    annot_full: 'major cell types',
    annot_sub: 'cell subtypes',
    annot_sub2: 'cell subtypes 2',
    annot_article: 'original article cluster',
    annot_auto: 'Automatic',
    cluster: 'cluster',
    'Sample ID': 'sample',
    'If metastatic': 'if metastatic disease',
};

export const PUBLIC_CONST_PARAMS = {
    annotfull: 'annot_full',
    annotSub2: 'annot_sub2',
    annotSub: 'annot_sub',
    annotArticle: 'annot_article',
    seuratClusters: 'seurat_clusters',
    annotAuto: 'annot_auto',
    removeArray: ['Species', 'orig.ident', 'Days since diagnosed', 'If sorted'],
    removeArray1: ['annot_full', 'annot_sub', 'annot_sub2', 'Sample ID'],
    annot_cell: ['annot_full', 'annot_sub', 'annot_sub2', 'annot_article', 'annot_auto'],
    sampleID: ['Sample ID', 'sample'],
    removeDoublet: [],
};

export const RankGeneTableHeader = {
    // 'mean_raw', ====> 'Average expression (raw)',
    newAdd: ['mean', 'mean_raw'],
    oldTableHeader: ['cluster', 'GeneID', 'mean',  'LogFC', 'Pct1', 'score', 'scores', 'Pvalue', 'PvalueAdj', 'Pct2'],
    newTableHeader: ['cluster', 'Gene id', 'Average expression (normalized)',  'Log2FC', 'Pct1', 'Z score', 'z score', 'P_val', 'Adjusted p value', 'Pct2'],
};

export const rankGeneTableHeaderObj = {
    cluster: 'Degs.Table.Cluster',
    'Gene id': 'Degs.Table.Gene.ID',
    'Average expression (normalized)': 'Dega.Table.Average.Expression.Nnormalized',
    Log2FC: 'Log2FC',
    Pct1: 'Degs.Table.Pct1',
    'Z score': 'Degs.Table.Z.Score1',
    'z score': 'Degs.Table.Z.Score',
    'Adjusted p value': 'Degs.Table.P.Val.Adj',
    Pct2: 'Degs.Table.Pct2',
    P_val: 'P_val',
};

export const ALL_CELL_TYPE_FORMAT_LABEL = {
    annot_full: 'major cell types',
    annot_sub: 'cell subtypes',
    annot_sub2: 'cell subtypes 2',
    annot_article: 'original article cluster',
    annot_auto: 'Automatic',
    cluster: 'raw_cluster',
    raw_cluster: 'raw_cluster',
};

export const changeDefaultOrCustomize = {
    options: [
        {
            value: 'default',
            label: 'Umap.Change.Color.Default',
            disabled: false,
        },
        {
            value: 'customize',
            label: 'Umap.Change.Color.Customize',
            disabled: false,
        },
    ],
};

export const clusterPosition = {
    labelOnCluster: 'Label on cluster',
    labelOnSide: 'Label on side',
};

export const drawDimInfo = {
    spatial: 'Spatial',
    X_umap: 'umap',
    X_tsne: 'tsne',
    X_umap3D: 'umap3D',
    X_tsne3D: 'tsne3D',
};
