import { URL_PREFIX } from "@/const/const";
export default class myAnalysesServer {
    //用户 联系我们
    static USER_DATABASE_API_POST_USER_CONTACT_US = URL_PREFIX + "/account/contactUS";

    //faq页所有条目总查询
    static DATABASE_API_GET_ALL_FAQITEM = URL_PREFIX + '/content/listFaq';

    //内容列表
    static CONTENT_API_CONTENT_GET_LIST = URL_PREFIX + "/content/list";

    //日志更新
    static CONTENT_API_UPDATELOG = URL_PREFIX + "/dataset/updateLog";
}