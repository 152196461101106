<template>
  <div class="cn-container">
    <p class="a-link">Phone (Germany) - General：+49 221 16824777</p>
    <p class="a-link">Phone (Germany) - Tech support: +49 221 57003425</p>
    <p class="a-link">Phone (APAC)：+65 6990 5987</p>
    <p class="a-link">E-mail：sessupport@singleronbio.com</p>
    <div class="link-icon">
      <a href="https://twitter.com/singleron_bio?lang=en" target="_blank">
        <img src="@/assets/footer/home_twitter.png" alt="">
      </a>
      <a href="https://www.youtube.com/channel/UCZeGRq83pQv4ebiy16Nc-Rw" target="_blank">
        <img src="@/assets/footer/home_youtube.png" alt="">
      </a>
      <a href="https://www.linkedin.com/company/singleron-biotechnologies/mycompany/" target="_blank">
        <img src="@/assets/footer/home_in.png" alt="">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUsCom",
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>