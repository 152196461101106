import axios from 'axios'; // 引入axios
import qs from 'qs';
import {_isEmpty} from '@/utils/validate.js';
import instance from './http.js';
import {Message} from "element-ui";
import {PrevLoading} from '@/utils/load/loading.js';
import store from "@/store";
import router from "@/router";
import Vue from "vue";
import {Local} from "@/utils/storage";
import i18n from "@/locales";
import { checkIpAddress} from '@/api/login/index.js';

const SERVER_URL = process.env.VUE_APP_BASE_API;

//尝试阻止请求
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
let isExpired = false;
let isExisted = true;
export default class server {
  static __args() {
    let setting = {};
    if (arguments.length === 1 && typeof arguments[0] !== 'string') {
        setting = arguments[0];
    } else {
        setting.url = arguments[0];
        if (typeof arguments[1] === 'object') {
              /*为了解决js对象引用导致的数据被修改的问题*/
              setting.data = arguments[1];
              // setting.data.time = new Date().getTime();
              setting.success = arguments[2];
              setting.error = arguments[3];
        } else {
              setting.success = arguments[1];
              setting.error = arguments[2];
        }
    }
    return setting;
  }

  static __json(method, setting, isJson) {
    const type = isJson ? 'application/json' : 'application/x-www-form-urlencoded';
    instance.defaults.headers.common['X-Access-Token'] = server.getToken();
    instance.defaults.headers.common['content-type'] = type;

    PrevLoading.start();
    const singleronbioRequest = instance;
    if (method.toLowerCase() === 'get') {
        singleronbioRequest.get(
              setting.url, {
                params: setting.data,
                paramsSerializer: {
                  serialize: params => {
                    return qs.stringify(params, { arrayFormat: 'repeat' });
                },
              },
              }).then(setting.success).catch(setting.error);

    } else if (method.toLowerCase() === 'post') {
        if (!isJson) {
              const formData = new FormData();
              Object.keys(setting.data).forEach((key) => {
                formData.append(key, setting.data[key]);
              });
              setting.data = formData;
        }
        return singleronbioRequest.post(setting.url, setting.data).then(setting.success).catch(setting.error);
    } else if (method.toLowerCase() === 'put') {
        return singleronbioRequest.put(setting.url, setting.data).then(setting.success).catch(setting.error);
    } else if (method.toLowerCase() === 'delete') {
        if(type === 'application/x-www-form-urlencoded') {
            return singleronbioRequest.delete(
                setting.url,
                { data: qs.stringify(setting.data, { arrayFormat: 'repeat' })},
            ).then(setting.success).catch(setting.error);
        }
        return singleronbioRequest.delete(setting.url, { data: setting.data }).then(setting.success).catch(setting.error);
    }
  }


  static svg2tiff(url, parames, callback, success, error) {
    return axios.create(server.getAxiosSvg2TiffConfig('post', true, null, callback)).post(SERVER_URL +
        url + '?token=' + server.getToken(), parames, { cancelToken: source.token }).then(success).catch(error);
  }

    static getJSON() {
        this.__json('get', this.__args.apply(this, arguments), false);
    }

  static postForm() {
    this.__json('post', this.__args.apply(this, arguments), false);
  }

  static postJSON() {
    this.__json('post', this.__args.apply(this, arguments), true);
  }

  static putJSON() {
    this.__json('put', this.__args.apply(this, arguments), true);
  }

  static putFrom() {
    this.__json('put', this.__args.apply(this, arguments), false);
  }

  static deleteJSON() {
    this.__json('delete', this.__args.apply(this, arguments), true);
  }

    static deleteForm() {
        this.__json('delete', this.__args.apply(this, arguments), false);
    }

  static getAxiosSvg2TiffConfig(method, isJson = false, time, callback1, ...config) {
    const type = isJson ? 'application/json' : 'application/x-www-form-urlencoded';
    return {
      method: method,
      withCredentials: false, // send cookies when cross-domain requests
      crossDomain: true,
      timeout: time ? time : 600000, // request timeout
      ...config,
      headers: {
            'content-type': type,
            'X-Access-Token': server.getToken(),
      },
      responseType: 'blob',
    };
  }

  static getToken() {
      const userInfo = Local.get('user_info');
      if (_isEmpty(userInfo)) {
          return '';
      }
      return userInfo.token;
  }


  static _isEmpty(input) {
    return String(input) === 'null' || String(input) === 'undefined' || input === '';
  }


  static call(fn, response) {
    if (!_isEmpty(fn) && typeof fn === 'function') {
        if (!_isEmpty(response)) {
              return fn(response);
        } 
              return fn();
        
    }
  }

    static errorMsg(msg) {
        Message({
            message: msg,
            type: 'error',
            duration: 5 * 1000,
        });
    }

    static logout() {
        const routeName = ['login'];
        let pathUrl = '';
        if (routeName.includes(router.currentRoute.name)) {
            pathUrl = router.currentRoute.fullPath;
        } else {
            pathUrl = `/login?redirect=${router.currentRoute.fullPath}`;
        }

        store.dispatch('user/logout');
        router.push({ path: pathUrl });
    }

    /**
     * 登录过期，添加提示
     */

    static authTip() {
        const self = this;
        if (isExpired) {
            return;
        }
        Vue.prototype.$alert(`<strong>${i18n.t("message.login.expiredText")}</strong>`, `${i18n.t("message.user.logOutTitle")}`, {
            dangerouslyUseHTMLString: true,
            customClass: 'auth-message',
            callback: (e) => {
                console.log(e);
                self.logout();
                // store.dispatch('user/logout');
            },
        });
        isExpired = true;
    }
    static serverCloseTip() {
        const self = this;
        if (!isExisted) {
            return;
        }
        Vue.prototype.$alert(`<strong>${i18n.t('message.Undergoing.MaintenanceText')}</strong><br> <span>${i18n.t('message.Undergoing.Later')}</span>`, `${i18n.t("message.user.logOutTitle")}`, {
            dangerouslyUseHTMLString: true,
            customClass: 'auth-message',
            callback: (e) => {
                console.log(e);
                isExisted = true;
                self.logout();
            },
        });
        isExisted = false;
    }
    static initProject() {
        this.getIpAddress();
    }
    static getIpAddress() {
        const self = this;
        checkIpAddress().then(res => {
            if (res.data !== null) {
                console.log('country', res.data.country, 'code', res.data.countryIsoCode);
                if(res.data.countryIsoCode === 'CN') {
                    self.fixSingleronbioDomain(true);
                } else {
                    self.fixSingleronbioDomain(false);
                }
            }
        });
    }

    // celelenscloud.singleronbio.com 国内
    // https://celelenscloud.singleron.bio 国外
    static fixSingleronbioDomain(fix) {
        const domain = window.location.host;
        const msg = fix ? "Your area is in  china, we will redirect to the nearest service" : "Your area is not in  china, we will redirect to the nearest service";
        if (this.isContains(domain, "celelenscloud.singleronbio.com") && !fix) {
            this.redirectTip(msg, "https://celelenscloud.singleron.bio");
        }
        if (this.isContains(domain, "www.celelenscloud.cn") && !fix) {
            this.redirectTip(msg, "https://celelenscloud.singleron.bio");
        }
        if (this.isContains(domain, "celelenscloud.singleron.bio") && fix) {
            this.redirectTip(msg, "https://www.celelenscloud.cn/#/");
        }
    }

    static isContains(str, substr) {
        return str.indexOf(substr) >= 0;
    }

    /**
     * 跳转提示
     */

    static redirectTip(msg, url) {
        if (!isExisted) {
            return;
        }
        Vue.prototype.$alert('<strong>' + msg + '</strong>', 'Tips', {
            dangerouslyUseHTMLString: true,
            customClass: 'auth-message',
            callback: (e) => {
                console.log(e);
                isExisted = true;
                location.href = url;
            },
        });
        isExisted = false;
    }
}