import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5242d0bb&scoped=true"
import script from "./index.js?vue&type=script&scoped=true&lang=js&external"
export * from "./index.js?vue&type=script&scoped=true&lang=js&external"
import style0 from "./index.scss?vue&type=style&index=0&id=5242d0bb&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5242d0bb",
  null
  
)

export default component.exports