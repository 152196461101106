export const AccountInfoCN = {
    'Account.Menu.Account': '我的账户',
    'Account.Menu.Renew.Subscription': '续订',
    'Account.Menu.Security.Settings': '安全设置',
    'Account.Menu.Email.Address': '邮箱修改',
    'Account.Menu.Password': '密码修改',
    'Account.Menu.Login.log': '登录日志',
    'Account.Module.Username': '用户姓名',
    'Account.Module.First.Name': '名',
    'Account.Module.Phone': '手机',
    'Account.Module.Last.Name': '姓',
    'Account.Module.Institution.Name': '机构名称',
    'Account.Module.Account.Status': '账号状态',
    'Account.Module.Subscribe.Or.Renew': '订阅或续订',
    'Account.Module.Current.Email': '当前邮箱',
    'Account.Module.New.Email': '新建邮箱',
    'Account.Module.Authentication.Code': '验证码',
    'Account.Module.Current.Password': '当前密码',
    'Account.Module.New.Password': '新建密码',
    'Account.Module.Confirm.Password': '确认密码',
    'Account.Module.Last.Records': '最近10条',
    'Account.Module.Subscription.Description': '订阅说明',
    'Account.Log.Time': '时间',
    'Account.Log.Login.ip': '登录ip',
    'Account.Log.Location': '位置',
    'Account.Code': '验证码',
    
};

export const AccountInfoUS = {
    'Account.Menu.Account': 'My account',
    'Account.Menu.Renew.Subscription': 'Renew subscription',
    'Account.Menu.Security.Settings': 'Security settings',
    'Account.Menu.Email.Address': 'Change email address',
    'Account.Menu.Password': 'Change password',
    'Account.Menu.Login.log': 'Login log',
    'Account.Module.Username': 'Username',
    'Account.Module.First.Name': 'First name',
    'Account.Module.Last.Name': 'Last name',
    'Account.Module.Phone': 'Phone',
    'Account.Module.Institution.Name': 'Institution Name',
    'Account.Module.Account.Status': 'Account Status',
    'Account.Module.Subscribe.Or.Renew': 'Subscribe or Renew',
    'Account.Module.Current.Email': 'Current email',
    'Account.Module.New.Email': 'New email',
    'Account.Module.Authentication.Code': 'Authentication code',
    'Account.Module.Current.Password': 'Current password',
    'Account.Module.New.Password': 'New password',
    'Account.Module.Confirm.Password': 'Confirm password',
    'Account.Module.Last.Records': 'The last 10 records',
    'Account.Module.Subscription.Description': 'Subscription Description',
    'Account.Log.Time': 'Time',
    'Account.Log.Login.ip': 'Login ip',
    'Account.Log.Location': 'Location',
    'Account.Code': 'Get code',
};

