import { dataFromCollection, returnDict, returnSortByData } from './dataframe.js';
import { ALL_CELL_TYPE_FORMAT_LABEL } from '@/const/const';

// 替换路由链接
export function tpl_url(s, string) {
    const res = s.replace(new RegExp('{.*}', 'g'), string);
    return res;
}
export function getParamsByKey(val) {
    const map = new Map();
    if (location.href.indexOf("?") !== -1) {
        const splitArr = location.href.split('?');
        const searchArr = splitArr[1].split('&');
        for (let i = 0; i < searchArr.length; i++) {
            const mapComp = searchArr[i].split('=');
            map.set(mapComp[0], mapComp[1]);
        }
    }
    const ret = map.get(val);
    if (!ret) {
        return '/';
    }
    let result = decodeURIComponent(ret);
    for (const item of map.entries()) {
        if(item[0] !== 'redirect') {
            result = result + '&' + item[0] + '=' +item[1];
        }
    }
    return result;
}

// 公共方法 --- 处理成数组对象
export function setOptions(options, rename) {
    const group = options.map(item => {
        return { value: item, label: rename ? rename[item] : item};
    });
    return {
        options: group,
        value: group.length ? group[0].value : '',
    };
}


// 数组求和
export function getSumFromArr(arr) {
    return arr.reduce(function(prev, curr) {
        return prev + curr;
    });
}

  /**
 * 给散点图的点排序
 * nameArr 散点名
 * xaxis x轴数组
 * yaxis y轴数组
 * sortVariable
 */
export function  sortSpots(nameArr, xaxis, yaxis, percentageOfCells, expression, zaxis) {
    const df = dataFromCollection({
        column1: nameArr,
        column2: xaxis,
        column3: yaxis,
        column4: percentageOfCells,
        column5: expression,
        column6: zaxis === undefined ? [] : zaxis,
    }, ['column1', 'column2', 'column3', 'column4', 'column5', 'column6']);
    const params = 'column5';
    returnSortByData(df, params);
    const data = returnDict(df);
    return data;
}
// 设置悬浮展示框选中后展示的名称
export function setPopoverName(params) {
    let filterStr = '';
    Object.keys(params).forEach(item => {
        const renameGro = ALL_CELL_TYPE_FORMAT_LABEL[item] ? ALL_CELL_TYPE_FORMAT_LABEL[item] : item;
        filterStr += renameGro + ': ' + params[item].join(', ') + '; ';
    });
    return filterStr;
}