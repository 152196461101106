export const geneExpressionCN = {
    'Gene.Expression.Explore.Title': '基因表达',
    'Gene.Expression.Tabs.Gene': '基因',
    'Gene.Expression.Input.Gene.Ensembl': '基因名称/Ensembl ID',
    'Gene.Expression.Layer.Raw': 'Raw counts',
    'Gene.Expression.Layer.Normalized': 'Normalized counts',
    'Gene.Expression.Layer.Tips': 'Raw counts会受单个细胞实际测序深度偏差的影响。Normalized counts更适用于查看多个基因的表达比较。',

    'Gene.Expression.Collapse.Expression': '表达',
    'Gene.Expression.Collapse.Enrichment': '富集',
    'Gene.Expression.Group': '分组',
    'Gene.Expression.Feature.Plot': 'Feature plot',
    'Gene.Expression.Violin.Plot': 'Violin plot',

    'Gene.Expression.Collapse.Compare.By.Expression': '多基因表达比较',
    'Gene.Expression.Collapse.Compare.By.Enrichment': '基因集富集比较',
    'Gene.Expression.Heatmap': 'Heatmap',
    'Gene.Expression.Dot.Plot': 'Dot plot',
    'Gene.Expression.Dot.Percentage.Scale.Mode': '百分比模式: ',
    'Gene.Expression.Dot.Relative': '相对百分比',
    'Gene.Expression.Dot.Absolute': '绝对百分比',
    'Gene.Expression.Scale.Tips': '绝对百分比：0-100%，相对百分比：最小-最大%',

    'Gene.Expression.Collpase.Compare.By.Sample.Group': '组间表达比较',
    'Gene.Expression.Collpase.Compare.By.Sample.Group1': '组间富集比较',
    'Gene.Expression.Select.Cells.To.Compare': '选择两个细胞组进行比较',
    'Gene.Expression.Two.Groups.Tips': '*请从下方窗口中选择所有组别。',
    'Gene.Expression.Violin.Tips': ' 细胞太少会导致Violin plot的形状看起来像一条水平线。',

    'Gene.Expression.Tabs.Co.Expression': '共表达',
    'Gene.Expression.Scatter.Plot': 'Scatter plot',
    'Gene.Expression.Cutoff': '阈值',
    'Gene.Expression.Venn.Plot': 'Venn plot',
    'Gene.Expression.Stacked.Bar.Plot': 'Stacked Bar Plot',
    'Gene.Expression.Co.Expression.Tips': '该功能可显示两个目标基因是否共同表达，以及它们表达之间的相关系数。',

    'Gene.Expression.Tabs.Gene.Set': '基因集',
    'Gene.Expression.Gene.Set.Tips': '该功能以特征得分的形式展示目标基因集中基因的活性。',
    'Gene.Expression.Select.Gene.Sets': '选择基因集',
    "Gene.Expression.Singleron.Gene.Sets": '新格元TME相关基因集',
    "Gene.Expression.Create.New.Gene.Set": '创建新基因集',
    'Gene.Expression.Custom.Gene.List': '我的基因列表',
    'Gene.Expression.Selected.Gene.sets': ' 已选择基因集',
    'Gene.Expression.Error.Tips': '请填写详细信息',
    'Gene.Expression.Genes': '基因',

    'Gene.Expression.Or': '或',

    'Gene.Expression.Uploaded.Gene.Signature': '上传基因集',
    'Gene.Expression.Select.File': '选择文件',
    "Gene.Expression.Upload.File.Tips": '*选择基因集文件。每个文件包含一个基因集。文件中每行包含一个基因。',
    "Gene.Expression.Plot.Genes": '提交',

    "Gene.Expression.Search.History": '搜索历史',
    "Gene.Expression.History.No": 'No',
    'Gene.Expression.History.Add': '添加',
    'Gene.Expression.History.Search.Details': '搜索详情',
    'Gene.Expression.History.Time': '时间',

    'Gene.Expression.Example': '示例',
    'Gene.Expression.Names': '',
    'Gene.Expression.Tabs.Gene.Set1': '基因集',
    'Gene.Undetected.Gene.List': '未检测到的基因列表',
    'Gene.Upload.Gene.List': '上传基因列表',
    'Gene.Upload.Gene.List.Tips': '您上传的基因列表包含矩阵中未检测到的基因，请单击 {undetected} 下载并查看。',
};

export const geneExpressionUS = {
    'Gene.Expression.Explore.Title': 'Explore Gene Expression',
    'Gene.Expression.Tabs.Gene': 'Gene',
    'Gene.Expression.Input.Gene.Ensembl': 'Gene name/Ensembl ID',
    'Gene.Expression.Layer.Raw': 'Raw counts',
    'Gene.Expression.Layer.Normalized': 'Normalized counts',
    'Gene.Expression.Layer.Tips': 'Raw counts is subjected to the deviation of actual sequencing depth of individual cells. Normalized counts gives more reliable results for comparing the expression of multiple genes.',

    'Gene.Expression.Collapse.Expression': 'Expression',
    'Gene.Expression.Collapse.Enrichment': 'Enrichment',
    'Gene.Expression.Group': 'Group',
    'Gene.Expression.Feature.Plot': 'Feature plot',
    'Gene.Expression.Violin.Plot': 'Violin plot',

    'Gene.Expression.Collapse.Compare.By.Expression': 'Compare by expression',
    'Gene.Expression.Collapse.Compare.By.Enrichment': 'Compare by Enrichment',
    'Gene.Expression.Heatmap': 'Heatmap',
    'Gene.Expression.Dot.Plot': 'Dot plot',
    'Gene.Expression.Dot.Percentage.Scale.Mode': 'Percentage scale mode:',
    'Gene.Expression.Dot.Relative': 'Relative',
    'Gene.Expression.Dot.Absolute': 'Absolute',
    'Gene.Expression.Scale.Tips': 'absolute percentage: 0-100%, relative percentage: smalest-highest%',

    'Gene.Expression.Collpase.Compare.By.Sample.Group': 'Compare by sample group',
    'Gene.Expression.Collpase.Compare.By.Sample.Group1': 'Compare by sample group',
    'Gene.Expression.Select.Cells.To.Compare': 'Select two groups of cells to compare',
    'Gene.Expression.Two.Groups.Tips': '*Please choose from all groups in the window.',
    'Gene.Expression.Violin.Tips': 'Too few cells result in the violin shape looking like a horizontal line.',

    'Gene.Expression.Tabs.Co.Expression': 'Co-expression',
    'Gene.Expression.Scatter.Plot': 'Scatter plot',
    'Gene.Expression.Cutoff': 'Cutoff',
    'Gene.Expression.Venn.Plot': 'Venn plot',
    'Gene.Expression.Stacked.Bar.Plot': 'Stacked Bar Plot',
    'Gene.Expression.Co.Expression.Tips': 'The function shows if two target genes co-express, as well as the correlation coefficient between their expression profiles.',

    'Gene.Expression.Tabs.Gene.Set': 'Gene Set',
    'Gene.Expression.Gene.Set.Tips': 'The function shows activity of genes in target geneset set as a signature score.',
    'Gene.Expression.Select.Gene.Sets': 'Select gene sets',
    "Gene.Expression.Singleron.Gene.Sets": 'Singleron TME-related gene sets',
    "Gene.Expression.Create.New.Gene.Set": 'Create new geneset',
    'Gene.Expression.Custom.Gene.List': 'Custom gene list',
    'Gene.Expression.Selected.Gene.sets': 'Selected gene sets',
    'Gene.Expression.Error.Tips': 'Please complete the detail',
    'Gene.Expression.Genes': 'Genes',

    'Gene.Expression.Or': 'OR',

    'Gene.Expression.Uploaded.Gene.Signature': 'Upload gene signature',
    'Gene.Expression.Select.File': 'Select file',
    "Gene.Expression.Upload.File.Tips": '*Select gene set files. Each file contains one gene set. Each line in file contains a single gene.',
    "Gene.Expression.Plot.Genes": 'Plot genes',

    "Gene.Expression.Search.History": 'Search History',
    "Gene.Expression.History.No": 'No',
    'Gene.Expression.History.Add': 'Add',
    'Gene.Expression.History.Search.Details': 'Search details',
    'Gene.Expression.History.Time': 'Time',

    'Gene.Expression.Example': 'Example',
    'Gene.Expression.Names': 'names',
    'Gene.Expression.Tabs.Gene.Set1': 'Gene set',
    'Gene.Undetected.Gene.List': 'Undetected Gene List',
    'Gene.Upload.Gene.List': 'Upload gene list',
    'Gene.Upload.Gene.List.Tips': 'The gene list you uploaded contains undetected genes in the matrix, please click {undetected} to download and view.',
};